<footer class="main-footer style-two bg-color-21">
    <div class="auto-container">
        <div class="footer-top">
            <div class="widget-section">
                <div class="row clearfix">
                    <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget logo-widget">
                            <div class="widget-title">
                                <h3>About Company</h3>
                            </div>
                            <div class="text">
                                 <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners...<i class="fas fa-arrow-up"></i></p>
                            </div>
                            <div class="upload-btn"><a href="index-3.html"><i class="flaticon-cloud-computing"></i>Upload Resume</a></div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="links-list clearfix">
                                <li><a routerLink="/about_us">About Us</a></li>
                                <li><a routerLink="/learning">Learning</a></li>
                                <li><a routerLink="/jobs_in_japan">Jobs in Japan</a></li>	
                                <li><a routerLink="/resourcing">Resourcing</a></li>
                                <li><a routerLink="/technology">Technology</a></li>
                                <li><a routerLink="/blogs_news">Blogs &amp; News</a></li>
                                <li><a routerLink="/contact_us">Contact Us</a></li>
                                <li><a routerLink="/privacy_policy">Privacy Policy</a></li>
                                <li><a routerLink="/schedule_session">Schedule a Session</a></li>
                            </ul>
                            <div class="about-widget">
                            <ul class="social-links clearfix">
                                <li><h5>Connected:</h5></li>
                                <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 footer-column">
                        <div class="footer-widget post-widget">
                            <div class="widget-title">
                                <h3>Recent Post</h3>
                            </div>
                            <div class="post-inner">
                                <div class="post">
                                    <figure class="image-box"><a href="blog-details.html"><img src="assets/images/news/post-1.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">Transforming India: How to maximize benefits from the state of change</a></h5>
                                     <span class="post-date">03rd March 2021</span>
                                </div>
                                <div class="post">
                                    <figure class="image-box"><a href="blog-details.html"><img src="assets/images/news/post-3.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">3 Surprising Culture Shocks for Indians in Japan</a></h5>
                                    <span class="post-date">06th Feb 2021</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom clearfix">
            <div class="copyright pull-left">
                <p class="clr_2">Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
            </div>
            <ul class="footer-nav pull-right clearfix">
                <li class="clr_2">Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
            </ul>
        </div>
    </div>
</footer>
<button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button>