<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header></app-header>
   <!-- <header class="main-header">
        <div class="header-lower">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left clearfix">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                    <div class="mobile-nav-toggler">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu navbar-expand-md navbar-light">
                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix">
                                <li class="dropdown"><a href="learning.html">Learning</a>
                                    <ul>
                                        <li><a href="learning.html#japanese_training">Japanese Training</a></li>
                                        <li><a href="learning.html#technical_training">Technical Training</a></li>
                                        <li><a href="register.html">nihoncareers.com</a></li>
                                    </ul>
                                </li>    
                                <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                    <ul>
                                        <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                        <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                    <ul>
                                        <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                        <li><a href="resourcing_professionals.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="technology.html">Technology</a>
                                    <ul>
                                        <li><a href="technology.html#tech_services">Tech Services</a></li>
                                        <li><a href="technology.html#background_screening">Background Screening</a></li>
                                        <li><a href="learning.html#learnchain">Learnchain</a></li>
                                    </ul>
                                </li> 
                                <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                  <ul>
                                      <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="sticky-header">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                    <nav class="main-menu clearfix">
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="search-btn">
                            <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                        </div>
                    </li>
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
    </header> -->
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a href="index.html"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    
    <section class="page-title" style="background-image: url(assets/images/background/page-title.jpg);">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>About Us</h1>
                    <p>The goal of education is understanding; the goalof training is performance.</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a href="index.html">Home</a></li>
                    <li>About Us</li>
                </ul>
            </div>
        </div>
    </section>
    
    
    <section class="about-style-three" id="learnchain">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title centred">
                        <span class="top-title">Welcome to Nihon Edutech</span>
                        <h2>About Us</h2>
                        <p>... Our teams have a combined 100+ years of work experience in Japan</p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <figure class="img_pdg"><img src="assets/images/about_img1.jpg" alt="" class="brds"></figure>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="job-details-content">
                                <div class="text">
                                    <h3>About Nihon Edutech</h3>
                                    <ul class="list clearfix">
                                        <li><strong>NIHON EDUTECH</strong> has a singular focus on workforce creation for Japan Market</li>
                                        <li>Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.</li>
                                        <li>Our Key offerings are Japanese Language and culture training and finding Jobs in JAPAN – from technical interns (TITP) to specific skill workers (SSW) to highly skilled talent (IT / Engineers).</li>
                                        <li><a href="http://nihoncareers.com/" target="_blank">nihoncareers.com</a> is our unique AI driven digital platform – an end-to-end portal to drive sourcing to learning to placement and beyond.</li>
                                        <li>Approved by NSDC and in consortium with Confederation of Indian Industry (CII), we are the largest TITP Sending Organization from India, with over 120 technical interns sent to Japan in past 2+ years.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>CIN :</th>
                          <th>U74993TN2020PTC135113</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company Name :</td>
                          <td>NIHON EDUTECH PRIVATE LIMITED</td>
                        </tr>
                        <tr>
                          <td>Company Status :</td>
                          <td>Active</td>
                        </tr>
                        <tr>
                          <td>RoC :</td>
                          <td>Registrar of Companies,Chennai</td>
                        </tr>
                        <tr>
                          <td>Registration No.:</td>
                          <td>135113</td>
                        </tr>
                        <tr>
                          <td>Company Category :</td>
                          <td>Company limited by Shares</td>
                        </tr>
                        <tr>
                          <td>Company Sub-Category:</td>
                          <td>Non-govt company</td>
                        </tr>
                        <tr>
                          <td>Class of Company:</td>
                          <td>Private</td>
                        </tr>
                        <tr>
                          <td>Authorized Share Capital:</td>
                          <td>Rs.1,500,000</td>
                        </tr>
                        <tr>
                          <td>Paid-up Capital:</td>
                          <td>Rs.100,000</td>
                        </tr>
                        <tr>
                          <td>Business Activities:</td>
                          <td>Learning Platform services, Training, Placement and Technology Services</td>
                        </tr>
                      </tbody>
            </table>
        </div>
    </section>
    
    <app-footer></app-footer>
<!-- <footer class="main-footer style-two bg-color-1">
        <div class="auto-container">
            <div class="footer-top">
                <div class="widget-section">
                    <div class="row clearfix">
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget about-widget">
                                <div class="widget-title">
                                    <h3>About Company</h3>
                                </div>
                                <div class="text">
                                    <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                            <div class="footer-widget links-widget">
                                <div class="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul class="links-list clearfix">
                                    <li><a href="aboutus.html">About Us</a></li>
                                    <li><a href="learning.html">Learning</a></li>
                                    <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>	
                                    <li><a href="resourcing.html">Resourcing</a></li>
                                    <li><a href="technology.html">Technology</a></li>
                                    <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                    <li><a href="contactus.html">Contact Us</a></li>
                                    <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                    <li><a href="learning.html#schedule">Schedule a Session</a></li>
                                </ul>
                                <div class="about-widget">
                                <ul class="social-links clearfix">
                                    <li><h5>Connected:</h5></li>
                                    <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div></div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                            <div class="footer-widget callback-widget">
                                <div class="pattern-layer">
                                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                    <div class="pattern-2"></div>
                                    <div class="pattern-3"></div>
                                    <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                                </div>
                                <div class="widget-content">
                                    <form action="index-2.html" method="post" class="callback-form">
                                        <div class="row clearfix">
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="name" placeholder="Name*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="email" name="email" placeholder="Email*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="phone" placeholder="Phone*" required="">
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <div class="select-box">
                                        <select class="wide" style="display: none;">
                                           <option data-display="Please Select">Please Select</option>
                                           <option value="1">TITP</option>
                                           <option value="2">SSW/SSP</option>
                                           <option value="3">IT/Engineering</option>
                                        </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                    </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <button type="submit" class="theme-btn-one">Get Call Back</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom clearfix">
                <div class="copyright pull-left">
                    <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
                </div>
                <ul class="footer-nav pull-right clearfix">
                    <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
                </ul>
            </div>
        </div>
    </footer>
    <button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>