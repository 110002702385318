<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader">
            <div class="preloader-close">Preloader Close</div>
        </div>

        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'learning.Home' | translate }}</a></li>
                    <li>{{ 'learning.Learning' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>

    <section class="about-style-three" id="learnchain">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <figure><img src="{{learnExp.image}}" alt="" class="brds"></figure>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'learning.Learning Experience Platform' | translate }}</span>
                                <h2>{{learnExp.title}}</h2>
                            </div>
                            <div class="inner-box">
                                <p>{{learnExp.description}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="job-details-content">
                    <div class="text">
                        <h3>{{ 'learning.It is a full Self-service platform for :' | translate }}</h3>
                        <ul class="list clearfix">
                            <li *ngFor = "let item of learnExp.plateforms">{{item}}</li>
                            <!-- <li>Learners to pursue best-fit learning pathways and aggregate learnings and certificates across internal and MOOC learning platforms</li>
                            <li>Trainers to white-label and launch training and work with recruiters on custom training and placement plans</li>
                            <li>Recruiters to white-label and post jobs to search for best talent as well as collaborate with trainers on the platform</li>
                            <li>Corporates to help employees right skill from different sources of learning, integrate with HRMS platforms and much more</li>
                            <li>Coaches to provide guidance and continuous learning for career development</li> -->
                        </ul>
                    </div>
                    <div class="link"><a href="#" class="theme-btn-two">{{ 'learning.View More' | translate }}</a></div>
                </div>
            </div>
        </div>
    </section>

    <section class="growth-style-two bg-color-2" id="japanese_training">
        <div class="image-column" style="background-image: url(assets/images/background/growth-bg.jpg);"></div>
        <div class="auto-container">
            <div class="row align-items-center clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'learning.Nihon Edutech' | translate }}</span>
                                <h2>{{ 'learning.Japanese Language Training' | translate }}</h2>
                            </div>
                            <div class="inner-box">
                                <div class="text">
                                    <p>{{learnLng.description}}</p>
                                </div>
                            <div class="link"><a href="#" class="theme-btn-two">{{ 'learning.View More' | translate }}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                    <div class="text">
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="about-style-three" id="technical_training">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-xl-6 col-lg-12 col-sm-12 img_pdg2">
                    <figure><img src="{{techTraining.image}}" alt="" class="brds"></figure>
                </div>
                <div class="col-xl-6 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'learning.Nihon Edutech' | translate }}</span>
                                <h2>{{ 'learning.Technical Training' | translate }}</h2>
                            </div>
                            <div class="inner-box">
                                <p>{{techTraining.description1}}</p>
                                <p>{{techTraining.description2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="p_cont">{{techTraining.description3}}</p>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <h3 class="h3_ttl" style="margin-bottom:10px;">{{ 'learning.Nihoncareers will focus on the following niche technical and IT skills' | translate }}</h3>
                </div>
                <div class="col-xl-4 col-lg-12 col-sm-12">
                    <div class="job-details-content">
                        <div class="text">
                            <h3>{{ 'learning.Technical Skills' | translate }}</h3>
                            <ul class="list4 clearfix">
                                <li><i class="fas fa-tractor"></i>{{ 'learning.Agriculture' | translate }} </li>
                                <li><i class="fas fa-cogs"></i>{{ 'learning.Mechanic' | translate }} </li>
                                <li><i class="fas fa-pencil-ruler"></i>{{ 'learning.Manufacturing' | translate }} </li>
                                <li><i class="far fa-building"></i>{{ 'learning.Construction' | translate }} </li>
                                <li><i class="fas fa-user-md"></i>{{ 'learning.Careworker' | translate }} </li>
                            </ul>
                    </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-sm-12">
                <div class="job-details-content">
                        <div class="text">
                            <h3>IT Skills</h3>
                            <ul class="list4 clearfix">
                                <li><i class="fab fa-mixcloud"></i>{{ 'learning.Cloud (AWS/Azure/GCP)' | translate }} </li>
                                <li><i class="fas fa-infinity"></i>{{ 'learning.Devops' | translate }} </li>
                                <li><i class="fas fa-network-wired"></i>{{ 'learning.L1/L2/L3 Infrastructure support' | translate }} </li>
                                <li><i class="fab fa-react"></i>{{ 'learning.Data science (AI/ML)' | translate }} </li>
                                <li><i class="fas fa-layer-group"></i>{{ 'learning.Full stack Development' | translate }} </li>
                                <li><i class="fas fa-cloud"></i>{{ 'learning.SaaS Platforms (SAP S4/HANA, Salesforce, Workday, ServiceNow etc.)' | translate }} </li>
                            </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>