import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Privacy Policy';
  bannerDetails:any={
    title:"Privacy Policy",
    description:"Nihon Edutech Private Limited",
    image:"assets/images/background/page_prvacy.jpg",
  }
  privacyData:any;
	constructor(
		private titleService: Title,
		private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getPrivacyPolicySeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}

  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
  }
  
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getPrivacyPolicyBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getPrivacyPolicy()
		.pipe(first())
		.subscribe(
			data => {
        console.log('dada',data)
				if(data.status == 1){
          this.privacyData = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
  }

}
