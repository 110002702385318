<div class="boxed_wrapper">
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'technology_training.Home' | translate }}</a></li>
                    <li>{{ 'technology_training.Technology Training' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>
    <section class="about-style-three" id="technical_training">
       <div class="auto-container">
            <div class="row align-items-center clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{techService.title}}</span>
                                <h2>{{techService.subtitle}}</h2>
                            </div>
                            <div class="inner-box">
                                <p [innerHTML]="techService.description1"></p>
                                <p [innerHTML]="techService.description2"></p>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div class="text">
                        <img src="{{techService.image}}" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 mrgn_top">
                    <p [innerHTML]="techService.description3"></p>
                </div>
            </div>
        </div>
        <div class="auto-container">
            <div class="row clearfix" [innerHtml]="techService.description4">
               <div class="col-lg-12 col-md-12 col-sm-12">
                    <h3 class="h3_ttl" style="margin-bottom:10px;">Nihoncareers will focus on the following niche technical and IT skills.</h3>
                </div>
                <div class="col-xl-4 col-lg-12 col-sm-12">
                    <div class="job-details-content">
                        <div class="text">
                            <h3>Technical Skills</h3>
                            <ul class="list4 clearfix">
                                <li> Agriculture</li>
                                <li> Mechanic</li>
                                <li> Manufacturing</li>
                                <li> Construction</li>
                                <li> Careworker</li>
                            </ul>
                       </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-sm-12">
                   <div class="job-details-content">
                        <div class="text">
                            <h3>IT Skills</h3>
                            <ul class="list4 clearfix">
                                <li> Cloud (AWS/Azure/GCP)</li>
                                <li> Devops</li>
                                <li> L1/L2/L3 Infrastructure support</li>
                                <li> Data science (AI/ML)</li>
                                <li> Full stack Development</li>
                                <li> SaaS Platforms (SAP S4/HANA, Salesforce, Workday, ServiceNow etc.)</li>
                            </ul>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="faq-section">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                    <div class="inner-box">
                        <ul class="accordion-box">
                            <li *ngFor="let item of paragraphs; let i = index" class="accordion block">
                                <div class="acc-btn">
                                    <div id="pera_expand{{i}}" class="icon-outer"></div>
                                    <h5>{{item.title}}</h5>
                                </div>
                                <div class="acc-content">
                                    <div class="text">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="{{item.image}}" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height" [innerHTML]="item.description">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="accordion block active-block">
                                <div class="acc-btn active">
                                    <div class="icon-outer"></div>
                                    <h5>N5-N4-N3</h5>
                                </div>
                                <div class="acc-content current">
                                    <div class="text">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/n5-n5.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>N5-N4-N3</h3>
                                                <p>Conducted through native-<strong style="color:#ff5956;">Japanese trainers</strong>, Japanese language, Japan-specific manners and business processes are taught with an impetus on spoken language. Students can choose from among the different training programs based on their existing proficiency level and to meet the required competency for the <a href="#" style="text-decoration:underline; font-weight:700;">job/career</a> they want to pursue in Japan.</p>
                                                <ul class="list_1">
                                                    <li>Conducted through native-Japanese trainers</li>
                                                    <li>Japanese language, Japan-specific manners</li>
                                                    <li>Students can choose from among the different training programs</li>
                                                    <li>Japanese language to live and work in Japan</li>
                                                    <li>Business processes are taught with an impetus on spoken language</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="accordion block">
                                <div class="acc-btn">
                                    <div class="icon-outer"></div>
                                    <h5>TITP</h5>
                                </div>
                                <div class="acc-content">
                                    <div class="text">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 bdr_right">
                                                <img src="assets/images/titp_img1.jpg" />
                                            </div>
                                            <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>TITP</h3>
                                                <p><strong>TITP</strong> (<a href="#">https://otit.go.jp</a>), established by Japan to contribute to developing countries by skilling their interns through On-the-Job-Training (‘OJT’), is operated under the Ministry of <strong>Skill Development &amp; Entrepreneurship (MSDE)</strong> with National Skills Development Corporation (<a href="#">https://nsdc.in</a>) as the Implementing & Monitoring agency.</p>
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr class="table_bg">
                                                            <th>Manufacturing</th>
                                                            <th>Automobile Mechanics</th>
                                                            <th>Construction</th>
                                                            <th>Agriculture</th>
                                                            <th>Careworker</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <ul class="list_1">
                                                    <li>Conducted through native-Japanese trainers</li>
                                                    <li>Japanese language, Japan-specific manners</li>
                                                    <li>Students can choose from among the different training programs</li>
                                                    <li>Japanese language to live and work in Japan</li>
                                                    <li>Business processes are taught with an impetus on spoken language</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="accordion block">
                                    <div class="acc-btn">
                                       <div class="icon-outer"></div>
                                       <h5>Other</h5>
                                   </div>
                                   <div class="acc-content">
                                       <div class="text">
                                           <div class="row">
                                               <div class="col-md-4 col-sm-4 bdr_right">
                                                   <img src="assets/images/titp_img1.jpg" />
                                               </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>Other</h3>
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                                   <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                                   <p>Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                               </div></div>
                                       </div>
                                   </div>
                            </li>
                            <li class="accordion block">
                                    <div class="acc-btn">
                                       <div class="icon-outer"></div>
                                       <h5>Title 4</h5>
                                   </div>
                                   <div class="acc-content">
                                       <div class="text">
                                           <div class="row">
                                               <div class="col-md-4 col-sm-4 bdr_right">
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                               </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>Title 4</h3>
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                               </div></div>
                                       </div>
                                   </div>
                            </li>
                            <li class="accordion block">
                                    <div class="acc-btn">
                                       <div class="icon-outer"></div>
                                       <h5>TBD</h5>
                                   </div>
                                   <div class="acc-content">
                                       <div class="text">
                                           <div class="row">
                                               <div class="col-md-4 col-sm-4 bdr_right">
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                               </div>
                                                <div class="col-md-8 col-sm-8 panel_height">
                                                <h3>TBD</h3>
                                                   <p>Home Content : Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.</p>
                                               </div></div>
                                       </div>
                                   </div>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="outer-container">
        <div class="sec-title">
            <span class="top-title">{{ 'technology_training.Gallery' | translate }}</span>
        </div>
        <owl-carousel-o [options]="customOptionsVideos">
            <ng-container *ngFor = "let item of images">
                <ng-template carouselSlide>
                    <div  class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url({{item.image}});">
                                </div>
                                <div class="lower-cont">
                                    <h3>{{item.title}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
              </ng-container>
        </owl-carousel-o>
        <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
            <div *ngFor = "let item of images" class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url({{item.image}});">
                        </div>
                        <div class="lower-cont">
                            <h3>{{item.title}}</h3>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img1.jpg);">
                        </div>
                        <div class="lower-cont">
                            <h3>Manufacturing Interns Training</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img2.jpg);">
                        </div>
                        <div class="lower-cont">
                        <h3>CareWorker Interns Training</h3>
                    </div>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img3.jpg);">
                        </div>
                        <div class="lower-cont">
                        <h3>CareWorker Interns Training</h3>
                    </div>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img4.jpg);">
                        </div>
                    <div class="lower-cont">
                        <h3>CareWorker Interns Training</h3>
                    </div>
                    </div>
                    
                </div>
            </div> 
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img5.jpg);">
                        </div>
                        <div class="lower-cont">
                        <h3>CareWorker Interns Training</h3>
                    </div>
                    </div>
                </div>
            </div>                     -->
        <!-- </div> -->
    </div>
           
    <div class="outer-container">
        <div class="sec-title">
            <span class="top-title">{{ 'technology_training.Videos' | translate }}</span>
        </div>
        <owl-carousel-o [options]="customOptionsVideos">
            <ng-container *ngFor = "let item of videos">
                <ng-template carouselSlide>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url({{item.image}});">
                                    <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                            </div>
                            <div class="lower-cont">
                                <h3>{{item.title}}</h3>
                            </div>
                        </div>
                    </div>
                </ng-template>  
            </ng-container>
        </owl-carousel-o>
        <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
            <div *ngFor = "let item of videos" class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url({{item.image}});">
                            <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>{{item.title}}</h3>
                    </div>
                </div>
            </div> -->
            <!-- <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                            <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>Our Training Program</h3>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                            <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>2021-Japan Job Opportunities</h3>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                            <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>TITP Intern - Self-Intro by Balaji</h3>
                    </div>
                </div>
            </div>
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                            <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                    </div>
                </div>
            </div> 
            <div class="project-block-one">
                <div id="image_block_1">
                    <div class="image-box">
                        <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                            <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                <i class="flaticon-play"></i>
                            </a>
                            <div class="border"></div>
                        </div>
                    </div>
                    <div class="lower-cont">
                        <h3>TITP Intern - Self-Intro by Pankaj</h3>
                    </div>
                </div>
            </div>                     -->
        <!-- </div> -->
    </div>
           
    <section class="testimonial-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title">
                <span class="top-title">{{ 'technology_training.Student Testimonials' | translate }}</span>
            </div>
            <owl-carousel-o [options]="customOptionsStudent">
                <ng-container *ngFor="let item of studentTestimonial">
                    <ng-template carouselSlide>
                        <div class="testimonial-block-one">
                            <div class="inner-box">
                                <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                                <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                <div class="author-box">
                                    <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                    <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                </div>
                                <div class="text">
                                    <p>{{item.description1}}</p>
                                    <p>{{item.description2}}</p>
                                </div>
                            </div>
                        </div> 
                    </ng-template>  
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="testimonial-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <div *ngFor="let item of studentTestimonial" class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                            <h3>{{item.name}}<span>{{item.training}}</span></h3>
                        </div>
                        <div class="text">
                            <p>{{item.description1}}</p>
                            <p>{{item.description2}}</p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/testimonials1.jpg" alt=""></figure>
                            <h3>Carmel Alexander<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I have learnt Japanese language and culture in Nihon Edutech by native Japanese</p>
                            <p>私たちは日本語と日本語の文川日本一テックという会 社で日本人からおしえていただきましたしました</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/testimonials2.jpg" alt=""></figure>
                            <h3>Sheik Mohaideen Badhusha<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>Nihon Edutech is giving several jobs opportunities in Japan such as construction, nursing, agriculture and other sectors, and also providing language and culture training within a short duration.</p>
                            <p>日本語や文化などのトレーニングの短い期間内に日本でいくつかの仕事の機会を与えてくれてる</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/testimonials1.jpg" alt=""></figure>
                            <h3>Jeyaraman<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I am in Japan, the land of rising sun, for almost 2 1/2 years. I am really glad to be here with very kind Japanese people.</p>
                            <p>約2年半位ここのぼる太陽の国に住んでますここでこんな優しい日本人といるのがとてもうれしいと思い</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb"><img src="assets/images/testimonials2.jpg" alt=""></figure>
                            <h3>Suthakar<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I went to so many places and met so many people here in Japan. I have studied before that Japanese are serious on their work. Now I’m glad to actually experience it.</p>
                            <p>いろんな行ってきましたいろんな人と会いましたNE で勉強中で日本人が仕事で真面目と聞いたことありますけどそれを実際に経験することができていいなぁと思ってます</p>
                        </div>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </section>
    <app-footer></app-footer>
</div>
    <!-- <div class="boxed_wrapper">
        <app-header></app-header>
        <div class="mobile-menu">
             <div class="menu-backdrop"></div>
             <div class="close-btn"><i class="fas fa-times"></i></div>
             <nav class="menu-box">
                 <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                 <div class="menu-outer"></div>
             </nav>
         </div>
         <section class="page-title" style="background-image: url({{bannerDetails.image}});">
             <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
             <div class="auto-container">
                 <div class="content-box">
                     <div class="title-box centred">
                         <h1>{{bannerDetails.title}}</h1>
                         <p>{{bannerDetails.description}}</p>
                     </div>
                     <ul class="bread-crumb clearfix">
                         <li><a routerLink="/">{{ 'technology_training.Home' | translate }}</a></li>
                         <li>{{ 'technology_training.Technical Training' | translate }}</li>
                     </ul>
                 </div>
             </div>
         </section>
         
         <section class="about-style-three" id="technical_training">
             <div class="auto-container">
                 <div class="row clearfix">
                     <div class="col-xl-6 col-lg-12 col-sm-12 img_pdg2">
                         <figure><img src="{{techService.image}}" alt="" class="brds"></figure>
                     </div>
                     <div class="col-xl-6 col-lg-12 col-sm-12">
                         <div id="content_block_1">
                             <div class="content-box">
                                 <div class="sec-title">
                                     <span class="top-title">{{ 'technology_training.Nihon Edutech' | translate }}</span>
                                     <h2>{{ 'technology_training.Technical Training' | translate }}</h2>
                                 </div>
                                 <div class="inner-box">
                                     <p>{{techService.description1}}</p>
                                     <p>{{techService.description2}}</p>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <p class="p_cont">{{techService.description3}}</p>
                 <div class="col-lg-12 col-md-12 col-sm-12">
                                  <h3 class="h3_ttl" style="margin-bottom:10px;">{{ 'technology_training.Nihoncareers will focus on the following niche technical and IT skills.' | translate }}</h3>
                                 </div>
                     <div class="col-xl-4 col-lg-12 col-sm-12">
                         <div class="job-details-content">
                             <div class="text">
                                 <h3>{{ 'technology_training.Technical Skills' | translate }}</h3>
                                 <ul class="list4 clearfix">
                                     <li><i class="fas fa-tractor"></i>{{ 'technology_training.Agriculture' | translate }} </li>
                                     <li><i class="fas fa-cogs"></i> {{ 'technology_training.Mechanic' | translate }}</li>
                                     <li><i class="fas fa-pencil-ruler"></i> {{ 'technology_training.Manufacturing' | translate }}</li>
                                     <li><i class="far fa-building"></i> {{ 'technology_training.Construction' | translate }}</li>
                                     <li><i class="fas fa-user-md"></i> {{ 'technology_training.Careworker' | translate }}</li>
                                 </ul>
                         </div>
                         </div>
                     </div>
                     <div class="col-xl-4 col-lg-12 col-sm-12">
                     <div class="job-details-content">
                             <div class="text">
                                 <h3>{{ 'technology_training.IT Skills' | translate }}</h3>
                                 <ul class="list4 clearfix">
                                     <li><i class="fab fa-mixcloud"></i> {{ 'technology_training.Cloud (AWS/Azure/GCP)' | translate }}</li>
                                     <li><i class="fas fa-infinity"></i> {{ 'technology_training.Devops' | translate }}</li>
                                     <li><i class="fas fa-network-wired"></i> {{ 'technology_training.L1/L2/L3 Infrastructure support' | translate }}</li>
                                     <li><i class="fab fa-react"></i> {{ 'technology_training.Data science (AI/ML)' | translate }}</li>
                                     <li><i class="fas fa-layer-group"></i> {{ 'technology_training.Full stack Development' | translate }}</li>
                                     <li><i class="fas fa-cloud"></i> {{ 'technology_training.SaaS Platforms (SAP S4/HANA, Salesforce, Workday, ServiceNow etc.)' | translate }}</li>
                                 </ul>
                         </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <app-footer></app-footer>
    </div> -->