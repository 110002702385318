import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
declare let $: any;

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.css']
})
export class FooterTwoComponent implements OnInit {
  constructor() { }
  public loadScript() {  
    var dynamicScripts = [
      ""+environment.base_url_assets+"/assets/js/script.js"
    ];      
    for (let i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      document.getElementsByTagName('footer')[0].appendChild(node);
    }
  }

  ngOnInit(): void {
    this.loadScript();
    if($('.scroll-to-target').length){
      $(".scroll-to-target").on('click', function() {
        var target = $(".scroll-to-target").attr('data-target');
         // animate
         $('html, body').animate({
           scrollTop: $(target).offset().top
         }, 1000);
      });
    }
    if($('.preloader-close').length)$('.preloader-close').click();
  }

}
