import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private translate: TranslateService
  ) {
    let lng = localStorage.getItem('lng');
    if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
  }

  lngChangeHandler(lng:any){
    localStorage.setItem('lng',lng);
    location.reload();
  }

  ngOnInit(): void {
    
  }

}

