<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <!--Banner Silder-->
    <!-- <section class="banner-section style-one">
        <div class="banner-carousel owl-theme owl-carousel owl-dots-none owl-nav-none">
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>{{ slider1_title }}</span>
                                <h1>{{ slider1_desc1 }}</h1>
                                <p>{{ slider1_desc2 }}</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-2.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>Get your dream job in Japan</span>
                                <h1>By learng language and Technical skills</h1>
                                <p>with Nihon Edutech</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-3.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-7 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>Recruit Trained staff for Japan</span>
                                <h1>with language and technical skills</h1>
                                <p>Trained by our experts at Nihon Edutech</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div>  
                        </div>
                    </div> 
                </div>
            </div>
             <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url(assets/images/banner/banner-3a.jpg)"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>Nihon Edutech offers solutions in niche technologies that can automate</span>
                                <h1>Business processes and provide a superior ROI to customer’s technology investments.</h1>
                                <p>The solutions offered include Staff Augmentation, Team Augmentation and Project services using onsite, offshore and nearshore delivery models.</p>
                                <div class="btn-box">
                                    <a routerLink="/" class="theme-btn-two">{{ 'Home.Learn More' | translate }}</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section> -->
    <section class="banner-section style-one">
        <div class="banner-carousel owl-theme owl-carousel owl-dots-none owl-nav-none">
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>{{ slider1_title }}</span>
                                <h1>{{ slider1_desc1 }}</h1>
                                <p>{{ slider1_desc2 }}</p>
                                <div class="btn-box">
                                    <a href="{{slider1_btn_link}}" class="theme-btn-two">Learn More</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider2_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>{{slider2_title}}</span>
                                <h1>{{slider2_desc1}}</h1>
                                <p>{{slider2_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider2_btn_link}}" class="theme-btn-two">Learn More</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
            <div class="slide-item style-one">
                <div class="image-layer" style="background-image:url({{slider3_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-1.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-2.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-7 col-lg-12 col-md-12 content-column">
                            <div class="content-box">
                                <span>{{slider3_title}}</span>
                                <h1>{{slider3_desc1}}</h1>
                                <p>{{slider3_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider3_btn_link}}" class="theme-btn-two">Learn More</a>
                                </div>
                            </div>  
                        </div>
                    </div> 
                </div>
            </div>
             <div class="slide-item style-two">
                <div class="image-layer" style="background-image:url({{slider4_img}})"></div>
                <div class="pattern-layer">
                    <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-3.png);"></div>
                    <div class="pattern-2" style="background-image: url(assets/images/shape/pattern-4.png);"></div>
                </div>
                <div class="auto-container">
                    <div class="row clearfix">
                        <div class="col-xl-6 col-lg-12 col-md-12 offset-xl-6 content-column">
                            <div class="content-box">
                                <span>{{slider4_title}}</span>
                                <h1>{{slider4_desc1}}</h1>
                                <p>{{slider4_desc2}}</p>
                                <div class="btn-box">
                                    <a href="{{slider4_btn_link}}" class="theme-btn-two">Learn More</a>
                                </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section>

    <!-- Three Card -->
    <!-- <section class="feature-section">
        <div class="outer-container">
            <div id="content_block_4">
                <div class="content-box">
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-7.png" alt=""></figure>
                                        <h3><span>01</span><a routerLink="/learning">{{ 'Home.Learning' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Japanese Training Technical Training' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                       <figure class="icon-box"><img src="assets/images/icons/icon-9.png" alt=""></figure>
                                        <h3><span>02</span><a routerLink="/register">{{ 'Home.Integrated Platform' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>www.nihoncareers.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                         <figure class="icon-box"><img src="assets/images/icons/icon-8.png" alt=""></figure>
                                        <h3><span>03</span><a routerLink="/jobs_in_japan_semi_skilled">{{ 'Home.Jobs in Japan' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Semi Skilled Professionals' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="feature-section">
        <div class="outer-container">
            <div id="content_block_4">
                <div class="content-box">
                    <div class="inner-box">
                        <div class="row clearfix">
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-7.png" alt=""></figure>
                                        <h3><a routerLink="/japanese-training">{{ 'Home.Learning' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Japanese Training Technical Training' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                       <figure class="icon-box"><img src="assets/images/icons/icon-9.png" alt=""></figure>
                                        <h3><a href="https://nihoncareers.com/" target="_blank">{{ 'Home.Integrated Platform' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.www.nihoncareers.com' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 single-column">
                                <div class="single-item wow fadeInRight animated animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                                    <div class="inner">
                                         <figure class="icon-box"><img src="assets/images/icons/icon-8.png" alt=""></figure>
                                        <h3><a routerLink="/jobs_in_japan_professional">{{ 'Home.Jobs in Japan' | translate }}<i class="flaticon-right-arrow"></i></a></h3>
                                        <p>{{ 'Home.Semi Skilled Professionals' | translate }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!-- What We Do -->
    <!-- <section class="about-style-two about-page">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                         <div id="image_block_1">
                            <div class="image-box">
                                <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-14.png);"></div>
                                <div class="video-inner bdr_r1" style="background-image: url({{whatWeDo.video_image}});">
                                    <a href="{{whatWeDo.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                                <figure class="image-1"><img src="{{whatWeDo.image}}" alt=""></figure>
                                <figure class="image-2 wow slideInLeft animated animated" data-wow-delay="00ms"><img src="{{whatWeDo.images}}" alt="" class="bdr_r"></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box">
                                <div class="sec-title centred">
                                <h2>{{ 'Home.What We do' | translate }}</h2>
                            </div>		   
                                <div class="text">
                                    <p>{{whatWeDo.description}}</p>
                                </div>
                                <div class="author-box">
                                    <div class="author-text">
                                        <h3>{{ 'Home.Get your dream job in Japan' | translate }}</h3>
                                    </div>
                                    <div class="author-info">
                                        <span class="designation"><a routerLink="/register" target=_blank>{{ 'Home.www.nihoncareers.com' | translate }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="about-style-two about-page">
        <div class="auto-container">
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                         <div id="image_block_1">
                            <div class="image-box">
                                <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-14.png);"></div>
                                <div class="video-inner bdr_r1" style="background-image: url({{whatWeDo.video_image}});">
                                    <a href="{{whatWeDo.video_link}}" class="lightbox-image video-btn" data-caption="">
                                        <i class="flaticon-play"></i>
                                    </a>
                                    <div class="border"></div>
                                </div>
                                <figure class="image-1"><img src="{{whatWeDo.image}}" alt=""></figure>
                                <figure class="image-2 wow slideInLeft animated animated" data-wow-delay="00ms"><img src="{{whatWeDo.images}}" alt="" class="bdr_r"></figure>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box">
                                <div class="sec-title centred">
                                <h2 class="h2_ttl">{{ 'Home.What We do?' | translate }}</h2>
                            </div>		   
                                <div class="text">
                                    <p [innerHTML]="whatWeDo.description"></p>
                                    <!-- <p>For corporates, we  offer technical services specialising in latest technologies and remote engineers from India.</p>
                                    <p>We serve customers across the industry verticals of Agriculture, Automobile, Construction, Financial Service, Healthcare and Markerng.</p> -->
                                </div>
                                <div class="author-box">
                                    <div class="author-text">
                                        <h3>{{ 'Home.Get your dream job in Japan' | translate }}</h3>
                                    </div>
                                    <div class="author-info">
                                        <span class="designation"><a routerLink="/register" target=_blank>{{ 'Home.www.nihoncareers.com' | translate }}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Our Team -->
    <!-- <section class="team-style-four bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Our Executive Team' | translate }}</span>
                <h2>{{ 'Home.Team Behind Nihon Edutech' | translate }}</h2>
                <p>{{ 'Home.We help companies thrive b creating good jobs' | translate }}</p>
            </div>
            <div *ngFor = "let item of teamData" class="row clearfix">
                <div class="col-lg-2 col-md-6 col-sm-12 team-block">
                    <div class="team-block-one wow fadeInUp animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div class="inner-box">
                            <figure class="image-box">
                                <img src="{{item.image}}" alt="">
                                <div class="share-box">
                                    <p><a href="{{item.linkedin_url}}"><i class="fab fa-linkedin"></i></a></p>
                                </div>
                            </figure>
                            <div class="lower-content">
                                <h3><a routerLink="/">{{item.name}}</a></h3>
                                <span class="designation">{{item.occupation}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- Pages Link -->
    <!-- <section class="service-details">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2>{{ 'Home.Our Key Offerings' | translate }}</h2>
                <p>{{ 'Home.Choose a job you love, and you will never have to work a day in your life' | translate }}</p>
            </div>
            <div class="row clearfix">
                <div class="col-md-12 col-sm-12 image-column">
                    <div class="service-details-content">                   
                        <div class="content-four">
                            <div class="inner-box">
                                <div class="row clearfix">
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-book-reader"></i></div>
                                                <a routerLink="/japanese-training"><h5>{{ 'Home.Japanese Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Japanese Language/culture training' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                             <li>
                                                <div class="icon-box"><i class="fas fa-microchip"></i></div>
                                                <a routerLink="/technical_training"><h5>{{ 'Home.Technology Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learn emergent technologies' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>  
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-users"></i></div>
                                                <a routerLink="/learnchain"><h5>{{ 'Home.Learnchain' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learning and Career Aggregation Platform' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fab fa-windows"></i></div>
                                                <a routerLink="/register"><h5>{{ 'Home.Nihon Careers' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Platform to Up-skill and find jobs in Japan' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                         <li>
                                                <div class="icon-box"><i class="fas fa-user-cog"></i></div>
                                                 <a routerLink="/ssw_ssp"><h5>{{ 'Home.Semi Skilled Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Specific Skilled Workers/Program' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-tie"></i></div>
                                                <a routerLink="/it_engineers"><h5>{{ 'Home.IT/ Engineering Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Training and placement of IT Bilingual Talent' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <section class="service-details">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Our Key Offerings' | translate }}</h2>
                <p>{{ 'Home.Choose a job you love, and you will never have to work a day in your life' | translate }}</p>
            </div>
            <div class="row clearfix">
                <div class="col-md-12 col-sm-12 image-column">
                    <div class="service-details-content">                   
                        <div class="content-four">
                            <div class="inner-box">
                                <div class="row clearfix">
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-book-reader"></i></div>
                                                <a routerLink="/japanese-training"><h5>{{ 'Home.Japanese Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Japanese Language/culture training' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                             <li>
                                                <div class="icon-box"><i class="fas fa-microchip"></i></div>
                                                <a routerLink="/technical_training"><h5>{{ 'Home.Technology Training' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learn emergent technologies' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>  
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                             <li>
                                                <div class="icon-box"><i class="fas fa-users"></i></div>
                                                <a routerLink="/learnchain"><h5>{{ 'Home.Learnchain' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Learning and Career Aggregation Platform' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fab fa-windows"></i></div>
                                                <a href="https://nihoncareers.com/" target="_blank"><h5>{{ 'Home.Nihon Careers' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Platform to Up-skill and find jobs in Japan' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-12 column">
                                        <ul class="list-item clearfix">
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-cog"></i></div>
                                                 <a routerLink="/jobs_in_japan_semi_skilled"><h5>{{ 'Home.Semi Skilled Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Specific Skilled Workers/Program' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                            <li>
                                                <div class="icon-box"><i class="fas fa-user-tie"></i></div>
                                                <a routerLink="/jobs_in_japan_professional"><h5>{{ 'Home.IT/ Engineering Jobs' | translate }}</h5>
                                                <div class="more-content">
                                                    <div class="menu-icon"><i class="flaticon-menu"></i><i class="flaticon-menu"></i></div>
                                                    <div class="text">
                                                        <p>{{ 'Home.Training and placement of IT Bilingual Talent' | translate }}</p>
                                                    </div>
                                                </div></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Technical Training Program -->
    <section class="welcome-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{technical_train.title}}</h2>
                <p>{{technical_train.subtitle}}</p>
            </div>
            <div class="row clearfix">
                <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                    <figure class="image-box js-tilt"><img src="{{technical_train.image}}" class="brds" alt=""></figure>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="tabs-box">
                                <div class="tab-btn-box">
                                    <ul class="tab-btns tab-buttons clearfix">
                                        <li class="tab-btn active-btn" data-tab="#tab-1">
                                            <i class="employ-icon flaticon-businessman"></i>
                                            <h5>Learn Japanese</h5>
                                            <i class="arrow-icon flaticon-up-arrow-2"></i>
                                        </li>
                                        <li class="tab-btn" data-tab="#tab-2">
                                            <i class="employ-icon flaticon-employer"></i>
                                            <h5>Upgrade Skills</h5>
                                            <i class="arrow-icon flaticon-up-arrow-2"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabs-content">
                                    <div class="tab active-tab" id="tab-1" [innerHTML]="technical_train.description1">
                                        <div class="inner-box">
                                            <h5>Learn Japanese</h5>
                                            <h2>Add Japanese language skill to your resume for more job opportunities</h2>
                                            <p>Get trained by our experienced teachers and develop business communication skills in Japanese language.</p>
                                            <p>Join growing number of candidates who have been successfully placed in Japan by Nihonedutech.</p>
                                            <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i>Contact us for free Demo class</a></div>
                                        </div>
                                    </div>
                                    <div class="tab" id="tab-2" [innerHTML]="technical_train.description2">
                                        <div class="inner-box">
                                            <h5>Upgrade Skills</h5>
                                            <h2>Nihonedutech will help you learn  the right skill for a great career in Japan.</h2>
                                            <p>Up-skilling in niche technical/IT skills in addition to the Japanese language is key to finding successful job placements in Japan.</p>
                                            <p>Join our Technical training program conducted as per the requirements of our Japanese clients and get placed in Japan</p>
                                            <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i>Know more about technical training</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Skills in Demand in Japan -->
    <section class="about-section">
        <div class="auto-container">
        <div class="sec-title centred">
                <h2 class="h2_ttl">{{about_sec.title}}</h2>
                <p>{{about_sec.subtitle}}</p>
            </div>
            <div class="inner-container">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_3">
                            <div class="content-box" [innerHTML]="about_sec.description1">
                                <p>Japan is technologically advanced nation and market leader in many sectors of the global economy. Japan offers greater opportunities for blue-collar and white-collar jobs. From  IT professional to skilled/semi-skilled workers from various sectors like automobile, mechanical, textile, healthcare, or construction.</p>
                                <p>Irrespective of your educational back ground Nihon edutech trains you in Japanese language skills and technical skills and makes you ready for TITP and SSW visa.</p>
                                <div class="text">
                                <div class="row clearfix">
                                <div class="col-lg-6 col-md-6">
                                    <h3 class="h3_ttl">IT Skills</h3>
                                    <ul class="list_1">
                                        <li>Cloud</li> 
                                        <li>Devops</li>
                                        <li>Infrastructure support</li>
                                        <li>Data science </li>
                                        <li>Full stack Development</li>
                                        <li>SaaS Platforms</li>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                <h3 class="h3_ttl">Non IT/ Technical Skills</h3>
                                    <ul class="list_1">
                                        <li>Agriculture</li>
                                        <li>Mechanical</li>
                                        <li>Manufacturing</li>
                                        <li>Construction</li>
                                        <li>Careworker</li>
                                    </ul>	
                                </div>
                                </div>
                                </div>
                                <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i>Upload CV / Find suitable job</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                        <div id="content_block_2">
                            <div class="content-box centred">
                                <figure class="image-box"><img src="{{about_sec.image}}" class="brds" alt=""></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Looking for IT professional -->
    <section class="recruitment-technology bg-color-2">
        <div class="auto-container">
        <div class="sec-title centred">
                <h2 class="h2_ttl">{{recruitment_sec.title}} <br> {{recruitment_sec.subtitle}}</h2>
            </div>
            <div class="row clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 image-column">
                    <figure class="image-box js-tilt"><img src="{{recruitment_sec.image}}" class="brds" alt=""></figure>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 content-column" [innerHTML]="recruitment_sec.description1">
                    <div id="content_block_4">
                        <div class="content-box">
                            <div id="content_block_3">
                            <div class="content-box">
                            <p><strong>Nihonedutech specialises in recruiting bilingual professionals for Japanese companies across a broad range of industries and sectors.</strong></p>
                            <p>We focus on efficient matching of Japanese companies that want to hire bilingual professionals  with the right talent across all levels of skill sets.
                                <p>We can help you quickly and easily find remote engineers for.</p>
                                <p>Our robust technology platform <a href="https://nihoncareers.com/" target="_blank">Nihoncareers.com</a> helps you find the right candidate for your requirement.
                                <p>Explore the advantages of recruiting through us</p>
                            <div class="link"><a href="contactus.html"><i class="flaticon-right-arrow"></i> You can post your requirement here</a></div>
                            </div></div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Blogs and news -->
    <section class="service-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Blogs & News' | translate }}</h2>
                <p>{{ 'Home.Smarter Business Solutions. Better Customer Relations' | translate }}</p>
            </div>
            <owl-carousel-o [options]="customOptionsBlogNews">
                <ng-container *ngFor = "let item of blogNews">
                    <ng-template carouselSlide>
                        <div class="service-block-one">
                            <div class="inner-box">
                                <figure class="image-box"><img src="{{item.image}}" alt=""></figure>
                                <div class="lower-content">
                                    <div class="content-box">
                                        <div class="inner">
                                            <!-- <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure> -->
                                            <h4>{{item.title}}</h4>
                                        </div>
                                        <div class="link"><a routerLink="['/blogs_news',item.blogs_news_id]">{{ 'Home.More Details' | translate }}</a></div>
                                    </div>
                                    <div class="overlay-content">
                                        <p>{{item.description}}</p>
                                        <a routerLink="/blogs_news/{{item.blogs_news_id}}"><i class="flaticon-right-arrow"></i>{{ 'Home.More Details' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="three-item-carousel owl-carousel owl-theme owl-nav-none owl-dot-style-one">
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Transforming India</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/news/blog1.png" alt=""></figure>
                                    <h4>The world of Kanji</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog3.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>3 Surprising Culture</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Temporary</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""></figure>
                                    <h4>Direct Hire</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog3.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>Contract</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog1.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-4.png" alt=""></figure>
                                    <h4>Temporary</h4>
                                </div>
                                <div class="link"><a href="temprory-staffing.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="temprory-staffing.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-5.png" alt=""></figure>
                                    <h4>Direct Hire</h4>
                                </div>
                                <div class="link"><a href="direct-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="direct-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service-block-one">
                    <div class="inner-box">
                        <figure class="image-box"><img src="assets/images/news/blog2.jpg" alt=""></figure>
                        <div class="lower-content">
                            <div class="content-box">
                                <div class="inner">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-6.png" alt=""></figure>
                                    <h4>Contract</h4>
                                </div>
                                <div class="link"><a href="contract-hire.html">More Details</a></div>
                            </div>
                            <div class="overlay-content">
                                <p>Explain to you how this idea denouncing pleasure & praising pain was born.</p>
                                <a href="contract-hire.html"><i class="flaticon-right-arrow"></i>More details</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </section>

    <!-- Student Testimonial  nihon v7-->
    <!-- <section class="testimonial-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title">
                <span class="top-title">{{ 'Home.Student Testimonials' | translate }}</span>
                <h2>{{ 'Home.Highest Student Satisfaction' | translate }}</h2>
            </div>
            <div class="testimonial-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-1.png" alt="">
                            </figure>
                            <h3>Carmel Alexander<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I have learnt Japanese language and culture in Nihon Edutech by native Japanese</p>
                            <p>私たちは日本語と日本語の文川日本一テックという会 社で日本人からおしえていただきましたしました</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Sheik Mohaideen Badhusha<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>Nihon Edutech is giving several jobs opportunities in Japan such as construction, nursing, agriculture and other sectors, and also providing language and culture training within a short duration.</p>
                            <p>日本語や文化などのトレーニングの短い期間内に日本でいくつかの仕事の機会を与えてくれてる</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Jeyaraman<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I am in Japan, the land of rising sun, for almost 2 1/2 years. I am really glad to be here with very kind Japanese people.</p>
                            <p>約2年半位ここのぼる太陽の国に住んでますここでこんな優しい日本人といるのがとてもうれしいと思い</p>
                        </div>
                    </div>
                </div>
                <div class="testimonial-block-one">
                    <div class="inner-box">
                        <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                        <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                        <div class="author-box">
                            <figure class="author-thumb">
                                <img src="assets/images/resource/testimonial-2.png" alt="">
                            </figure>
                            <h3>Suthakar<span>TITP Intern - 2018 batch</span></h3>
                        </div>
                        <div class="text">
                            <p>I went to so many places and met so many people here in Japan. I have studied before that Japanese are serious on their work. Now I’m glad to actually experience it.</p>
                            <p>いろんな行ってきましたいろんな人と会いましたNE で勉強中で日本人が仕事で真面目と聞いたことありますけどそれを実際に経験することができていいなぁと思ってます</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- Gallary  nihon v7-->
    <!-- <section class="portfolio-masonry protfolio-page">
        <div class="auto-container">
         <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Our Network & Industry Experience are Unmatched' | translate }}</span>
                <h2>{{ 'Home.Gallery' | translate }}</h2>
            </div>
            <div class="sortable-masonry">
                <div class="items-container row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img5.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon</span>
                                        <h3><a href="#">CareWorker Interns<br> Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img2.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img3.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img4.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">Manufacturing Interns <br />Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all presentation consulting market recruitment">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img8.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting presentation Recruitment technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img7.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                       <h3><a href="#">CareWorker Interns Training</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 masonry-item small-column all consulting technology">
                        <div class="project-block-one">
                            <div class="inner-box">
                                <figure class="image-box">
                                    <img src="assets/images/gallery/gal_img6.jpg" alt="">
                                </figure>
                                <div class="content-box">
                                    <div class="text">
                                        <span>Nihon Edutech</span>
                                        <h3><a href="#">Technical Interns <br />in Japan</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more-btn centred"><a routerLink="/gallery">{{ 'Home.More Gallery' | translate }}</a></div>
            </div>
        </div>
    </section> -->
    <section class="portfolio-masonry protfolio-page">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Gallery' | translate }}</h2>
                <span>{{ 'Home.Our Network & Industry Experience are Unmatched' | translate }}</span>
            </div>
            <div class="outer-container">
                <owl-carousel-o [options]="customOptionsVideos">
                    <ng-container *ngFor = "let item of images">
                        <ng-template carouselSlide>
                            <div  class="project-block-one">
                                <div id="image_block_1">
                                    <div class="image-box">
                                        <div class="video-inner" style="background-image: url({{item.image}});">
                                        </div>
                                        <div class="lower-cont">
                                            <h3>{{item.title}}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                      </ng-container>
                </owl-carousel-o>
                <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img1.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>Manufacturing Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img2.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img3.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img4.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="project-block-one">
                        <div id="image_block_1">
                            <div class="image-box">
                                <div class="video-inner" style="background-image: url(assets/images/gallery/new_gal_img5.jpg);">
                                </div>
                                <div class="lower-cont">
                                    <h3>CareWorker Interns Training</h3>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div> -->
            </div>
            <div class="more-btn centred"><a routerLink="/gallery">{{ 'Home.More Gallery' | translate }}</a></div>
        </div>
    </section>

    <!-- Videos -->
    <!-- <section class="project-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'Home.Training & Opportunities' | translate }}</span>
                <h2>{{ 'Home.Videos' | translate }}</h2>
            </div>
        </div>
        <div class="outer-container">
            <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                                <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>Our Training Program</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>2021-Japan Job Opportunities</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Balaji</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                                <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                                <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Pankaj</h3>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    </section>
     -->
     <section class="project-section bg-color-2">
        <div class="auto-container">
            <div class="sec-title centred">
                <h2 class="h2_ttl">{{ 'Home.Videos' | translate }}</h2>
                <span>{{ 'Home.Training & Opportunities' | translate }}</span>
            </div>
        </div>
        <div class="outer-container">
            <owl-carousel-o [options]="customOptionsVideos">
                <ng-container *ngFor = "let item of videos">
                    <ng-template carouselSlide>
                        <div class="project-block-one">
                            <div id="image_block_1">
                                <div class="image-box">
                                    <div class="video-inner" style="background-image: url({{item.image}});">
                                        <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                            <i class="flaticon-play"></i>
                                        </a>
                                        <div class="border"></div>
                                    </div>
                                </div>
                                <div class="lower-cont">
                                    <h3>{{item.title}}</h3>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="project-carousel owl-carousel owl-theme owl-dots-none">
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img1.jpg);">
                                <a href="https://www.youtube.com/embed/NtjriBQMArQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>Our Training Program</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img2.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>2021-Japan Job Opportunities</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img3.jpg);">
                                <a href="https://www.youtube.com/embed/XEC8BsuYALM" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Balaji</h3>
                        </div>
                    </div>
                </div>
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img4.jpg);">
                                <a href="https://www.youtube.com/embed/LyhGLJ6gZmQ" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Bakath Singh</h3>
                        </div>
                    </div>
                </div> 
                <div class="project-block-one">
                   <div id="image_block_1">
                        <div class="image-box">
                            <div class="video-inner" style="background-image: url(assets/images/gallery/video_img5.jpg);">
                                <a href="https://www.youtube.com/embed/LW1Lt4BQvsU" class="lightbox-image video-btn" data-caption="">
                                    <i class="flaticon-play"></i>
                                </a>
                                <div class="border"></div>
                            </div>
                        </div>
                        <div class="lower-cont">
                            <h3>TITP Intern - Self-Intro by Pankaj</h3>
                        </div>
                    </div>
                </div>                    
            </div> -->
        </div>
    </section>

    <!-- Jobs in Japan nihon v7-->
    <!-- <section class="portfolio-masonry protfolio-page">
        <div class="auto-container">
         <div class="sec-title centred">
                <span class="top-title">Nihon Careers :</span>
                <h2>For Jobs in Japan</h2>
            </div>
            <div class="upper-box">
                <div class="row clearfix">
                    <div class="col-lg-6 col-md-12 col-sm-12 title-column">
                        <div class="title-inner">
                            <h2>For Jobs in Japan</h2>
                            <p>Japan has huge demand for low to semi-skilled and highly-skilled professionals.</p>
                            <p><a href="http://www.nihonCareers.com" target="_blank">NihonCareers</a> is the platform services offering on Japanese language and culture training, technical/technology trainings.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                        <div class="inner-box">
                            <div class="row clearfix">
                                <div class="col-lg-6 col-md-6 col-sm-12 column">
                                    <div class="single-item">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-54.png" alt=""></figure>
                                        <h3>345,000</h3>
                                        <p>Demand for technical resources across 14 sectors by 2024</p>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 column">
                                    <div class="single-item">
                                        <figure class="icon-box"><img src="assets/images/icons/icon-55.png" alt=""></figure>
                                        <h3>780,000</h3>
                                        <p>Demand for Software Engineers by 2030</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <!-- Our Clients-->
    <!-- <section class="clients-section alternet-2">
        <div class="outer-container">
            <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure *ngFor="let item of company" class="clients-logo-box">
                    <a href="{{item.website_url}}"><img src="{{item.logo}}" alt=""></a>
                    <span class="logo-title"><a href="{{item.website_url}}">Visit Website</a></span>
                </figure>
            </div>
        </div>
    </section> -->
    <section class="clients-section alternet-2">
        <div class="col-xl-12 col-lg-12 col-sm-12">
            <h2 class="clients_ttl">{{ 'Home.Our Clients' | translate }}</h2>
        </div>
        <div class="outer-container">
            <owl-carousel-o [options]="customOptionsClient">
                <ng-container *ngFor = "let item of client">
                    <ng-template carouselSlide>
                        <figure class="clients-logo-box">
                            <a href="{{item.website_url}}"><img src="{{item.logo}}" alt=""></a>
                            <span class="logo-title"><a href="{{item.website_url}}">{{ 'Home.Visit Website' | translate }}</a></span>
                        </figure>
                    </ng-template>  
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure class="clients-logo-box">
                    <a href="index.html"><img src="assets/images/clients/clients-logo-4.png" alt=""></a>
                    <span class="logo-title"><a href="index.html">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.cii.in/" target="_blank"><img src="assets/images/clients/clients-logo-5.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.cii.in/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.maxell.com/" target="_blank"><img src="assets/images/clients/clients-logo-7.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.maxell.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/clients-logo-8.png" alt=""></a>
                    <span class="logo-title"><a href="https://minusculetechnologies.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="http://www.simplexsol.com/" target="_blank"><img src="assets/images/clients/clients-logo-9.png" alt=""></a>
                    <span class="logo-title"><a href="http://www.simplexsol.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.udtrucks.com/" target="_blank"><img src="assets/images/clients/clients-logo-10.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.udtrucks.com/" target="_blank">Visit Website</a></span>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.fujifilm.com/" target="_blank"><img src="assets/images/clients/clients-logo-6.png" alt=""></a>
                    <span class="logo-title"><a href="https://www.fujifilm.com/" target="_blank">Visit Website</a></span>
                </figure>
                
            </div> -->
        </div>
    </section>

    <section class="clients-section alternet-2">
        <div class="outer-container">
            <div class="col-xl-12 col-lg-12 col-sm-12">
                <h2 class="clients_ttl">{{ 'Home.Our Partners' | translate }}</h2>
            </div>
            <owl-carousel-o [options]="customOptionsClient">
                <ng-container *ngFor = "let item of partner">
                    <ng-template carouselSlide>
                        <figure class="clients-logo-box">
                            <a href="{{item.website_url}}"><img src="{{item.logo}}" alt=""></a>
                            <span class="logo-title"><a href="{{item.website_url}}">{{ 'Home.Visit Website' | translate }}</a></span>
                        </figure>
                    </ng-template>  
                </ng-container>
            </owl-carousel-o>
            <!-- <div class="clients-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                <figure class="clients-logo-box">
                    <a href="https://www.securekloud.com/" target="_blank"><img src="assets/images/clients/partnr_img1.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.steepgraph.com/" target="_blank"><img src="assets/images/clients/partnr_img2.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="http://www.rajasri.com/" target="_blank"><img src="assets/images/clients/partnr_img3.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://minusculetechnologies.com/" target="_blank"><img src="assets/images/clients/partnr_img4.png" alt=""></a>
                </figure>
                <figure class="clients-logo-box">
                    <a href="https://www.avvanz.com/"><img src="assets/images/clients/partnr_img5.png" alt=""></a>
                </figure>
            </div> -->
        </div>
    </section>

    <!-- Employment v7-->
    <!-- <section class="advice-section" style="background-image: url(assets/images/background/advice-1.jpg);">
        <div class="auto-container">
            <div class="content-inner clearfix">
                <div class="text pull-left"><h2>Employment Advice for Your Business, <a href="index-3.html">Start Here.</a></h2></div>
                <ul class="social-links pull-right">
                    <li><a href="index-3.html"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-google-plus-g"></i></a></li>
                    <li><a href="index-3.html"><i class="fab fa-youtube"></i></a></li>
                </ul>
            </div>
        </div>
    </section> -->

     <!-- Address Map v7-->
    <!-- <section class="contactinfo-section bg-color-2">
        <div class="image-column" style="background-image: url(assets/images/background/contact-bg-1.jpg);"></div>
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-24.png);"></div>
        <div class="auto-container">
            <div class="row align-items-center clearfix">
                <div class="col-xl-4 col-lg-6 col-md-12 content-column">
                    <div id="content_block_6">
                        <div class="content-box">
                            <div class="sec-title">
                                <span class="top-title">{{ 'Home.Endless Opportunities' | translate }}</span>
                                <h2>{{ 'Home.Touch With Us' | translate }}</h2>
                            </div>
                            <ul class="info-list clearfix">
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-39.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{address.country1}}</h4>
                                        <p>{{address.address1}}</p>
                                        <h4>{{address.country2}}</h4>
                                        <p>{{address.address2}}</p>
                                    </div>
                                </li>
                                <li>
                                    <figure class="icon-box"><img src="assets/images/icons/icon-40.png" alt=""></figure>
                                    <div class="inner">
                                        <h4>{{ 'Home.Call or Email' | translate }}</h4>
                                        <p>
                                            <a href="tel:{{address.phone1}}">{{address.phone1}}</a><br/>
                                            <a href="mailto:{{address.email1}}">{{address.email2}}</a>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-12 col-md-12 map-column">
                    <div class="map-inner">
                       <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7775.781621367692!2d80.225296!3d12.978835000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63894ea6dc3a0704!2sNihon%20Edutech%20Pvt.%20Limited!5e0!3m2!1sen!2sin!4v1616749039445!5m2!1sen!2sin" width="600" height="380" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    
    <app-footer></app-footer>
</div>