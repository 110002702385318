<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
    <app-header></app-header>
  <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'blog_news.Home' | translate }}</a></li>
                    <li>{{ 'blog_news.Blogs & News' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>
    <section class="sidebar-page-container blog-details">
        <div class="auto-container">
            <div class="row clearfix">
                <div class="col-lg-8 col-md-12 col-sm-12 content-side">
                    <div class="blog-details-content">
                        <div class="inner-box">
                            <figure class="image-box"><img src="{{blogNewsData[0].image}}" alt=""></figure>
                            <div class="text" [innerHTML]="blogNewsData[0].name">
                               <h2>The Drivers of Change in India</h2>
                                <p>We have seen our country take massive steps to implement transformative reforms before and it is time to do so again. This time, the demand isn’t just to improve the education level, it is to cater to a larger market: filling the gaps needed in a rapidly scaling digital, post-industrial economy while ensuring the learning is not only functional but consistent with change.</p>
                                <p>The economy is moving from investment and production driven to consumption, services and innovation driven. Gig economy and Shared value business models are rising, and this has scaled automation and digitization to an unprecedented scale. COVID-19 has not only accelerated digitization, but also has established the importance of digital upskilling. The industry has yet again shown how Volatile, Uncertain, Ambiguous and Uncertain it can be, and the ones who adapt the quickest, survive.</p>                 
                                <h2>Enabling Upskilling Reformation in India</h2>
                                <p>India needs a skill revolution to sustain the momentum it has gained in global standings. Even though India has made constant efforts in providing education for all, more efforts in upskilling are needed. Even though the state has initiated National Skill Development Corporation to upskill youth, more efforts and deeper engagement is required from the private sector to provide the vocational skills required by the industry and create a quality labour market in India.</p>
                                <p>With private sector stepping up, there would be a more streamlined process and competition that would enable quality content being revisited and updated with the need of the industry.</p>
                                <p>The bottleneck that persists is the accessibility and awareness of these skills to the masses. The issue that persists is the lack of quality upskilling tutors in their geography at a convenient time in their budget. This has created a huge competitive gap between those in favourable geographies to those in not so favourable ones. To make education and training ubiquitous, we need to leverage the deep penetration of internet and expand the usage of digital technologies that can be accessed at minimal cost to the user.</p>
                                <h2>Digital Upskilling, the way forward</h2>
                                <p>Adopting digital technologies would enable multi-channel learning avenues open to upskilling platforms. They can create Micro-curriculum to devise interesting content aided with AI, AR and VR and gamified learning systems to encourage maximum understanding and retention. The best part of digitalization is the ease of scaling up, as the only requirement from the user end would be a smartphone with internet.</p>
                                <p>The extended public-private partnership could bridge the gap between skills possessed by workforce and those required by the employers. Together, they can create joint programs and improve job placements for the workforce. Enterprises can provide state of the art facilities and resources for the workforce to upskill while Government can create a connect between various stakeholders.</p>
                                <p>A substantial amount of investment would be required from all the key stakeholders in the process. From the private sector who participate in upskilling, to constantly curate, develop and improve their resources, trainers and learning channels, from Government to constantly support the integration and create link between constantly changing stakeholders and their requirements as well as the masses who need to be upscaled to get the maximum benefit.</p>
                                <h2>Your role in the revolution</h2>
                                <p>We should emphasize on how upskilling is not limited to those lacking formal education, it is more so important to those with formal education who constantly upskill with the changing requirements of the world. And since education and upskilling is not limited to just geographical boundaries anymore, with the enabling digital technologies, jobs in a different geographical region is more and more possible by the day.</p>
                                <p>Even though a suitable policy, adoption of digital upskilling and private-public integration are major factors in the future of upskilling, the primary onus of deciding to prepare yourself lies in the hands of the population. If they can take advantage of the tipping point and grab the opportunity, they can easily accelerate their career trajectory.</p>
                                <p>But for a revolution to begin, we need to act now!</p>
                            </div>
                        </div>
                        <!-- <div class="post-share-option">
                            <div class="icon-box"><i class="flaticon-hashtag"></i></div>
                            <div class="social-box">
                                <ul class="social-links clearfix">
                                    <li><a href="blog-details.html"><i class="fab fa-facebook-f"></i>Facebook</a></li>
                                    <li><a href="blog-details.html"><i class="fab fa-twitter"></i>Twitter</a></li>
                                    <li><a href="blog-details.html"><i class="fab fa-linkedin-in"></i>Linkedin</a></li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="comments-form-area">
                            <div class="group-title">
                                <h2>{{ 'blog_news.Send Your Comment' | translate }}</h2>
                                <p>{{ 'blog_news.Your email address will not be published. Required fields are marked' | translate }} *</p>
                            </div>
                            <b style="color: red;">{{ errorMsg }}</b>
                            <b style="color: green;">{{ successMsg }}</b>
                            <form [formGroup]="enqForm" (ngSubmit)="onSubmit()" method="post" class="comment-form">
                                <div class="row clearfix">
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="name" placeholder="{{ 'blog_news.Name' | translate }}*" 
											formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">{{ 'blog_news.Name is required' | translate }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="email" name="email_id" placeholder="{{ 'blog_news.Email' | translate }}*"
											formControlName="email_id" [ngClass]="{ 'is-invalid': submitted && f.email_id.errors }">
                                        <div *ngIf="submitted && f.email_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.email_id.errors.required">{{ 'blog_news.Email is required' | translate }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <textarea name="comment" placeholder="{{ 'blog_news.Comment' | translate }} ..."
                                        formControlName="comment" [ngClass]="{ 'is-invalid': submitted && f.comment.errors }"></textarea>
                                        <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                                            <div *ngIf="f.comment.errors.required">{{ 'blog_news.Comment is required' | translate }}</div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                                        <button type="submit" [disabled]="loading" class="theme-btn-one">{{ 'blog_news.Send Message' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                    <div class="blog-sidebar">
                        <!-- <div class="search-widget">
                            <form action="blog-grid.html" method="post" class="search-form">
                                <div class="form-group">
                                    <input type="search" name="search-field" placeholder="Keyword" required="">
                                    <button type="submit"><i class="flaticon-loupe-1"></i></button>
                                </div>
                            </form>
                        </div> -->
                       <div class="sidebar-widget post-widget">
                            <div class="widget-title">
                                <h3>{{ 'blog_news.Recently Posted' | translate }}</h3>
                                <i class="flaticon-calendar"></i>
                            </div>
                            <div class="widget-content">
                                <div *ngFor="let item of recentBlogNewsData" class="post">
                                    <figure class="post-thumb"><a href="/blogs_news/{{item.blogs_news_id}}"><img src="{{item.image}}" alt=""></a></figure>
                                    <h5><a href="/blogs_news/{{item.blogs_news_id}}">{{item.title}}</a></h5>
                                    <!-- <span class="post-date">03th March, 2021</span> -->
                                </div>
                                <!-- <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/post-1.jpg" alt=""></a></figure>
                                    <h5><a href="blog-details.html">Transforming India: How to maximize benefits from the state of change</a></h5>
                                    <span class="post-date">03th March, 2021</span>
                                </div>
                                 <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/post-2.jpg" alt=""></a></figure>
                                    <span class="post-date">15th Feb, 2021</span>
                                    <h5><a href="blog-details.html">The world of Kanji</a></h5>
                                </div>
                                <div class="post">
                                    <figure class="post-thumb"><a href="blog-details.html"><img src="assets/images/news/post-3.jpg" alt=""></a></figure>
                                    <h5><a href="surprising.html">3 Surprising Culture Shocks for Indians in Japan</a></h5>
                                    <span class="post-date">06th Feb, 2021</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>