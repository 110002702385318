<div class="boxed_wrapper">
    <app-header></app-header>        
     <div class="mobile-menu">
         <div class="menu-backdrop"></div>
         <div class="close-btn"><i class="fas fa-times"></i></div>
             <nav class="menu-box">
                 <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
                 <div class="menu-outer"></div>
             </nav>
         </div>
         <section class="page-title" style="background-image: url({{bannerDetails.image}});">
             <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
             <div class="auto-container">
                 <div class="content-box">
                     <div class="title-box centred">
                         <h1>{{bannerDetails.title}}</h1>
                         <p>{{bannerDetails.description}}</p>
                     </div>
                     <ul class="bread-crumb clearfix">
                         <li><a routerLink="/">{{ 'job_in_japan_semi_skill.Home' | translate }}</a></li>
                         <li>{{ 'job_in_japan_semi_skill.Semi Skilled' | translate }}</li>
                     </ul>
                 </div>
             </div>
         </section>
         <section class="growth-style-two" id="japanese_training">
             <div class="auto-container">
                 <div class="row align-items-center clearfix">
                     <div class="col-xl-12 col-lg-12 col-sm-12">
                         <div class="sec-title centred">
                             <span class="top-title">{{japaneseTrain.title}}</span>
                             <h2>{{japaneseTrain.subtitle}}</h2>
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                         <div class="text">
                             <img src="{{japaneseTrain.image}}" />
                         </div>
                     </div>
                     <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                         <div id="content_block_1">
                             <div class="content-box">
                                 <div class="inner-box">
                                     <p [innerHTML]="japaneseTrain.description"></p>
                                     <p [innerHTML]="japaneseTrain.description2">Conducted by a team of native-Japanese trainers and Indian teachers, the training focuses on Japanese language/culture, Japanese language, Japan-specific manners and business processes with an impetus on spoken skills.  Students can choose from among the different training programs based on their existing proficiency level to meet the required competency for the career/academic/business goal they want to pursue in Japan. The entire program is hosted and driven using <a href="https://nihoncareers.com/">nihoncareers.com</a> platform.</p>                                 
                                 </div>
                             </div>
                         </div>
                     </div>
                     <div class="col-lg-12 col-md-12 col-sm-12 mrgn_top" [innerHTML]="japaneseTrain.description3">
                         <p><strong>Specifically, our Japanese courses are designed to help the students to</strong></p>
                         <ul class="list_6">
                             <li>securing a job and live/work in Japan</li>
                             <li>pursuing higher studies in Japan</li>
                             <li>to interact with Japanese customers and partners for business development etc.</li>
                         </ul>
                         <p>Japanese language proficiency levels vary from entry level (N5) through intermediate (N4, N3) to advanced levels (N2, N1). While N4 level proficiency is sufficient for semi-skilled workers, for high-skilled category such as IT, Engineering and other disciplines, the minimum expectation from Japan is N3 level and above.</p>
                         <p>Nihon Edutech provides Japanese language training through different programs and extend support to students for job placements in Japan. All the programs stress on conversational skills and teaches Japanese culture and etiquettes. The programs are conducted through a combination of on-line and physical classroom sessions (presently only on-line).</p>
                     </div>
                 </div>
             </div>
         </section>
             
         <section class="faq-section">
             <div class="auto-container">
                 <div class="row clearfix">
                     <div class="col-lg-12 col-md-12 col-sm-12 inner-column">
                         <div class="inner-box">
                             <ul class="accordion-box">
                                 <li *ngFor="let item of paragraphs; let i = index" class="accordion block">
                                     <div class="acc-btn">
                                         <div id="pera_expand{{i}}" class="icon-outer"></div>
                                         <h5>{{item.title}}</h5>
                                     </div>
                                     <div class="acc-content">
                                         <div class="text">
                                             <div class="row">
                                                 <div class="col-md-4 col-sm-4 bdr_right">
                                                     <img src="{{item.image}}" />
                                                 </div>
                                                 <div class="col-md-8 col-sm-8 panel_height" [innerHTML]="item.description">
                                                     
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </li>
                             </ul>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'job_in_japan_semi_skill.Gallery' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of images">
                     <ng-template carouselSlide>
                         <div  class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                     </div>
                                     <div class="lower-cont">
                                         <h3>{{item.title}}</h3>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </ng-template>
                   </ng-container>
             </owl-carousel-o>
         </div>
         <div class="auto-container">
             <div class="sec-title">
                 <span class="top-title">{{ 'job_in_japan_semi_skill.Videos' | translate }}</span>
             </div>
             <owl-carousel-o [options]="customOptionsVideos">
                 <ng-container *ngFor = "let item of videos">
                     <ng-template carouselSlide>
                         <div class="project-block-one">
                             <div id="image_block_1">
                                 <div class="image-box">
                                     <div class="video-inner" style="background-image: url({{item.image}});">
                                         <a href="{{item.video_link}}" class="lightbox-image video-btn" data-caption="">
                                             <i class="flaticon-play"></i>
                                         </a>
                                         <div class="border"></div>
                                     </div>
                                 </div>
                                 <div class="lower-cont">
                                     <h3>{{item.title}}</h3>
                                 </div>
                             </div>
                         </div>
                     </ng-template>  
                 </ng-container>
             </owl-carousel-o>
         </div>
                 
         <section class="testimonial-section bg-color-2">
             <div class="auto-container">
                 <div class="sec-title">
                     <span class="top-title">{{ 'job_in_japan_semi_skill.Student Testimonials' | translate }}</span>
                 </div>
                 <owl-carousel-o [options]="customOptionsStudent">
                     <ng-container *ngFor="let item of studentTestimonial">
                         <ng-template carouselSlide>
                             <div class="testimonial-block-one">
                                 <div class="inner-box">
                                     <div class="border-shap" style="background-image: url(assets/images/shape/border-1.png);"></div>
                                     <figure class="quote-box"><img src="assets/images/icons/quote-1.png" alt=""></figure>
                                     <div class="author-box">
                                         <figure class="author-thumb"><img src="{{item.image}}" alt=""></figure>
                                         <h3>{{item.name}}<span>{{item.training}}</span></h3>
                                     </div>
                                     <div class="text">
                                         <p>{{item.description1}}</p>
                                         <p>{{item.description2}}</p>
                                     </div>
                                 </div>
                             </div> 
                         </ng-template>  
                     </ng-container>
                 </owl-carousel-o>
             </div>
         </section>
        <app-footer></app-footer>
 </div>
