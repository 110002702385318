import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {

  API_URL: string = environment.apiUrl;
  
  constructor(private http: HttpClient) {
    // httpHeaders.append('key', 'fd');
  }

  // Forms
  register(data:any) {
    return this.http.post<any>(this.API_URL + 'register/store',data);
  }
  footerForm(data:any) {
    return this.http.post<any>(this.API_URL + 'enquire/store',data);
  }
  addDemo(data:any) {
    return this.http.post<any>(this.API_URL + 'demo/store',data);
  }

  // Home Page
  getHomeSeo() {
    return this.http.get<any>(this.API_URL + 'home/get_seo');
  }

  getHomeSlider() {
    return this.http.get<any>(this.API_URL + 'home/get_all_banner');
  }

  getHomeWhatWeDo() {
    return this.http.get<any>(this.API_URL + 'home/getWhatWeDo');
  }
  getDataBySlug(slug:any) {
    return this.http.get<any>(this.API_URL + 'home/getDataBySlug?slug='+slug);
  }

  getGallery(type:any,slug:any) {
    if(!slug)slug=1;
    return this.http.post<any>(this.API_URL + '/gallery/get_gallery',{type:type,slug:slug});
  }

  getAllTeam() {
    return this.http.post<any>(this.API_URL + 'team/get_all_team',{});
  }

  getStudentTestimonial(slug:any) {
    return this.http.get<any>(this.API_URL + 'student_testimonials/get_student_testimonials?slug='+slug);
  }

  getCompanyData(type:any) {
    return this.http.get<any>(this.API_URL + 'company/get_company?type='+type);
  }

  /** Learing */
  getLearningBanner() {
    return this.http.get<any>(this.API_URL + '/learning/get_banner');
  }
  getLearningExper() {
    return this.http.get<any>(this.API_URL + '/learning/get_learning_experience');
  }
  getLearningLangTrain() {
    return this.http.get<any>(this.API_URL + '/learning/get_language_training');
  }

  getTechnicalTrain() {
    return this.http.get<any>(this.API_URL + '/learning/get_technical_training');
  }
  getLearningSeo() {
    return this.http.get<any>(this.API_URL + '/learning/get_seo');
  }

  /**Jobs In Japan  */
  getJobInJapanSeo() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/get_seo');
  }
  getJobInJapanBanner() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/get_banner');
  }
  getJobInJapanTITP() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/get_titp');
  }

  /** Jobs In japan Semi skilled */
  getJIJSemiSkilledSeo() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/semi_skilled/get_seo');
  }
  getJIJSemiSkilledBanner() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/semi_skilled/get_banner');
  }
  getJIJSemiSkilledSSW() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/semi_skilled/get_ssw');
  }
  getJIJSemiSkilledProcess() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/semi_skilled/get_process');
  }
   /** Jobs In japan Prof */
   getJIJProfessionalSeo() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/professional/get_seo');
  }
  getJIJProfessionalBanner() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/professional/get_banner');
  }
  getJIJProfessionalEngineering() {
    return this.http.get<any>(this.API_URL + '/job_in_japan/professional/engineering');
  }

  /** Blogs & News */
  getBlogsNewsSeo() {
    return this.http.get<any>(this.API_URL + '/blogs_news/get_seo');
  }
  getBlogsNewsBanner() {
    return this.http.get<any>(this.API_URL + '/blogs_news/get_banner');
  }
  getBlogsNewsData() {
    return this.http.get<any>(this.API_URL + '/blogs_news/get_blogs_news');
  }
  getBlogsNewsDataBiId(_id:any) {
    return this.http.get<any>(this.API_URL + '/blogs_news/get_blogs_news?_id='+_id);
  }
  addBlogComment(data:any) {
    return this.http.post<any>(this.API_URL + '/blogs_news/add_comment',data);
  }

  /** Contact Us */
  getContactUsSeo() {
    return this.http.get<any>(this.API_URL + '/contact_us/get_seo');
  }
  getContactUsBanner() {
    return this.http.get<any>(this.API_URL + '/contact_us/get_banner');
  }
  getContactUsAddress() {
    return this.http.get<any>(this.API_URL + '/contact_us/get_address');
  }
  addContactUsForm(data:any) {
    return this.http.post<any>(this.API_URL + 'contact_us/store',data);
  }

  /** Resourcing */
  getResourcingSeo() {
    return this.http.get<any>(this.API_URL + '/resourcing/get_seo');
  }
  getResourcingBanner() {
    return this.http.get<any>(this.API_URL + '/resourcing/get_banner');
  }
  getResourcingSSW() {
    return this.http.get<any>(this.API_URL + '/resourcing/get_ssw');
  }
  /** Resourcing Semi skilled */
  getResourcingSemiSkilledSeo() {
    return this.http.get<any>(this.API_URL + '/resourcing/semi_skilled/get_seo');
  }
  getResourcingSemiSkilledBanner() {
    return this.http.get<any>(this.API_URL + '/resourcing/semi_skilled/get_banner');
  }
  getResourcingSemiSkilledSSW() {
    return this.http.get<any>(this.API_URL + '/resourcing/semi_skilled/get_ssw');
  }
  getResourcingSemiSkilledProcess() {
    return this.http.get<any>(this.API_URL + '/resourcing/semi_skilled/get_process');
  }
  /** Resourcing Prof */
  getResourcingProfessionalSeo() {
    return this.http.get<any>(this.API_URL + '/resourcing/professional/get_seo');
  }
  getResourcingProfessionalBanner() {
    return this.http.get<any>(this.API_URL + '/resourcing/professional/get_banner');
  }
  getResourcingProfessionalEngineering() {
    return this.http.get<any>(this.API_URL + '/resourcing/professional/engineering');
  }

  /** Technology */
  getTechnologySeo() {
    return this.http.get<any>(this.API_URL + '/technology/get_seo');
  }
  getTechnologyBanner() {
    return this.http.get<any>(this.API_URL + '/technology/get_banner');
  }
  getTechnologyTechService() {
    return this.http.get<any>(this.API_URL + '/technology/get_tech_service');
  }

  /** Japanese Training */
  getJapaneseTrainingSeo() {
    return this.http.get<any>(this.API_URL + '/japanese-training/get_seo');
  }
  getJapaneseTrainingBanner() {
    return this.http.get<any>(this.API_URL + '/japanese-training/get_banner');
  }
  getJapaneseTraining() {
    return this.http.get<any>(this.API_URL + '/japanese-training/get_training');
  }
  getJapaneseTrainingParagraph() {
    return this.http.get<any>(this.API_URL + '/japanese-training/get_paragraph');
  }

  /** Peragraph */
  getParagraph(slug:any) {
    return this.http.get<any>(this.API_URL + '/get_paragraph?slug='+slug);
  }

  /** Learnchain */
  getLearnchainSeo() {
    return this.http.get<any>(this.API_URL + '/learnchain/get_seo');
  }
  getLearnchainBanner() {
    return this.http.get<any>(this.API_URL + '/learnchain/get_banner');
  }
  getLearnchainLearnExp() {
    return this.http.get<any>(this.API_URL + '/learnchain/get_learning_experience');
  }

  /** Semi skilled Job */
  getSemiSkilledJobSeo() {
    return this.http.get<any>(this.API_URL + '/semi_skilled_job/get_seo');
  }
  getSemiSkilledJobBanner() {
    return this.http.get<any>(this.API_URL + '/semi_skilled_job/get_banner');
  }
  getSemiSkilledJobSSW() {
    return this.http.get<any>(this.API_URL + '/semi_skilled_job/get_ssw');
  }
  getSemiSkilledJobProcess() {
    return this.http.get<any>(this.API_URL + '/semi_skilled_job/get_process');
  }

  /** Technology Training*/
  getTechnologyTrainingSeo() {
    return this.http.get<any>(this.API_URL + '/technology_training/get_seo');
  }
  getTechnologyTrainingBanner() {
    return this.http.get<any>(this.API_URL + '/technology_training/get_banner');
  }
  getTechnologyTrainingTechService() {
    return this.http.get<any>(this.API_URL + '/technology_training/get_tech_service');
  }
  getTechnologyTrainingParagraph() {
    return this.http.get<any>(this.API_URL + '/technology_training/get_paragraph');
  }

  /** it engineering */
  getItEngineeringSeo() {
    return this.http.get<any>(this.API_URL + '/it_engineering/get_seo');
  }
  getItEngineeringBanner() {
    return this.http.get<any>(this.API_URL + '/it_engineering/get_banner');
  }
  getItEngineeringGetEng() {
    return this.http.get<any>(this.API_URL + '/it_engineering/get_engineering');
  }
  getItEngineeringGetProf() {
    return this.http.get<any>(this.API_URL + '/it_engineering/get_professionals');
  }

  /**Privacy Policy */
  getPrivacyPolicySeo() {
    return this.http.get<any>(this.API_URL + '/privacy_policy/get_seo');
  }
  getPrivacyPolicyBanner() {
    return this.http.get<any>(this.API_URL + '/privacy_policy/get_banner');
  }
  getPrivacyPolicy() {
    return this.http.get<any>(this.API_URL + '/privacy_policy/get_policy');
  }

  /**Schedule a session */
  getScheduleSessionSeo() {
    return this.http.get<any>(this.API_URL + '/schedule_session/get_seo');
  }
  getScheduleSessionBanner() {
    return this.http.get<any>(this.API_URL + '/schedule_session/get_banner');
  }

   // Tech Service
  getTechServiceSeo() {
    return this.http.get<any>(this.API_URL + 'tech_services/get_seo');
  }

  getTechServiceSlider() {
    return this.http.get<any>(this.API_URL + 'tech_services/get_all_banner');
  }
  getTechServicePartner() {
    return this.http.get<any>(this.API_URL + 'tech_services/getPartners');
  }
}
