import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.css']
})
export class LearningComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Learning';
  bannerDetails:any={
    title:"Learning Experience Platform",
    description:"Use existing training setup and revenue as base and leverage track record to acquire employers",
    image:"assets/images/background/page-title.jpg",
  }
  learnExp:any = {
    title:"Provides learning personalization and customization capabilities",
    description:"Learnchain is a robust Marketplace Platform for Trainers to launch and manage training programs, for Learners to access learning Anytime | Anywhere | Anydevice | Anysource, and for Recruiters to help post and search for best talent.",
    image:"assets/images/learnchain_img1.jpg",
    plateforms:["Learners to pursue best-fit learning pathways and aggregate learnings and certificates across internal and MOOC learning platforms","Trainers to white-label and launch training and work with recruiters on custom training and placement plans"]
  }
  learnLng:any={
    description:"Ready-For-Japan program teaches Japanese language, culture, etiquettes and work models/practices to students and professionals in India so that they become proficient in Japanese language to live and work in Japan. Conducted through native-Japanese trainers, Japanese language, Japan-specific manners and business processes are taught with an impetus on spoken language. Students can choose from among the different training programs based on their existing proficiency level and to meet the required competency for the job/career they want to pursue in Japan."
  }
  techTraining:any = {
    description1:"Technical training is an integral part of nihoncareers platform and upskilling in technical and relevant IT skills in addition to the Japanese language skills is key to finding successful job placements in Japan.",
    description2:"Freelance trainers and training institutions can self register in nihoncareers platform for free and can use the basic features of the platform to administer and run their training programs digitally in structured manner.",
    description3:"The platform enables their training programs to become visible to the candidates registered in the platform thereby providing them an immediate and large marketplace to attract students/trainees for their programs. In addition, trainers can collaborate with the recruiters registered in the nihoncareers platform and customize their training for specific requirements of recruiters to address specific job demands.",
    image:"assets/images/technical_img1.jpg"
  }
  constructor(
		private titleService: Title,
		private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    translate.setDefaultLang(lng);
    this.requestService.getLearningSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getLearningBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getLearningExper()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.learnExp = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getLearningLangTrain()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.learnLng = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getTechnicalTrain()
		.pipe(first())
		.subscribe(
			data => {
        console.log('teach',data)
				if(data.status == 1){
          this.techTraining = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  }

}
