import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';

@Component({
  selector: 'app-resourcing',
  templateUrl: './resourcing.component.html',
  styleUrls: ['./resourcing.component.css']
})
export class ResourcingComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Resourcing';
  bannerDetails:any={
    title:"Resourcing",
    description:"Use existing training setup and revenue as base and leverage track record to acquire employers, then expand",
    image:"assets/images/background/page-title.jpg",
  }
  sswDetails:any = {
    image:"assets/images/ssw_img1.jpg",
    description1:"SSW is a new status of residence introduced by Japan in April 2019 to attract foreign talent with a certain level of expertise and skill to address the skill shortage experienced in certain sectors due to declining population and aging society.",
    description2:"There is a good opportunity for people skilled in the specified sectors undere this scheme to travel and work in Japan for upto 5 years, as long as they posess the technical skills and learn the Japanese language skills. The candidates need to clear both language and technical exams before they become eligible to travel under the SSW scheme.",
    it_engineering:"Nihon Edutech conducts an exclusive Ready-For-Japan program for IT / Engineering professionals who aspire to go to Japan to build their career. This program also enables companies to expand their business footprint in Japan by letting its employees undergo this unique skill enablement program. With only half a day spent on this program, the employees continue to work in their projects for rest of the day. For successful candidates, Nihon Edutech helps find jobs in Japan."
  }
  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private requestService: RequestService
  ) {
    let lng = localStorage.getItem('lng');
      if(lng == null || lng == '' || lng == undefined)lng = 'en';
      translate.setDefaultLang(lng);
      this.requestService.getResourcingSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
  }
  updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getResourcingBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
    this.requestService.getResourcingSSW()
		.pipe(first())
		.subscribe(
			data => {
        console.log('data',data);
				if(data.status == 1){
					this.sswDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  }

}
