<div class="boxed_wrapper">
    <div class="loader-wrap">
    <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
    <div class="layer layer-one"><span class="overlay"></span></div>
    <div class="layer layer-two"><span class="overlay"></span></div>        
    <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
<app-header></app-header>
<!-- <header class="main-header">
    <div class="header-lower">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
                <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                <div class="mobile-nav-toggler">
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light">
                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul class="navigation clearfix">
                            <li class="dropdown"><a href="learning.html">Learning</a>
                                <ul>
                                    <li><a href="learning.html#japanese_training">Japanese Training</a></li>
                                    <li><a href="learning.html#technical_training">Technical Training</a></li>
                                    <li><a href="register.html">nihoncareers.com</a></li>
                                </ul>
                            </li>    
                            <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                <ul>
                                    <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                    <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                </ul>
                            </li> 
                            <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                <ul>
                                    <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                    <li><a href="resourcing_professionals.html">Professional</a></li>
                                </ul>
                            </li> 
                            <li class="dropdown"><a href="technology.html">Technology</a>
                                <ul>
                                    <li><a href="technology.html#tech_services">Tech Services</a></li>
                                    <li><a href="technology.html#background_screening">Background Screening</a></li>
                                    <li><a href="learning.html#learnchain">Learnchain</a></li>
                                </ul>
                            </li> 
                            <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                              <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a href="register.html" class="theme-btn-one">Register</a>
                </li>
                <li>
                    <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                </li>
            </ul>
        </div>
    </div>
    
    <div class="sticky-header">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left">
                <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                <nav class="main-menu clearfix">
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="search-btn">
                        <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                    </div>
                </li>
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                            <ul>
                              <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a href="register.html" class="theme-btn-one">Register</a>
                </li>
                <li>
                    <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                </li>
            </ul>
        </div>
    </div>
</header> -->
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    <nav class="menu-box">
        <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
        <div class="menu-outer"></div>
    </nav>
</div>
<section class="page-title" style="background-image: url({{bannerDetails.image}});">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
    <div class="auto-container">
        <div class="content-box">
            <div class="title-box centred">
                <h1>{{bannerDetails.title}}</h1>
                <p>{{bannerDetails.description}}</p>
            </div>
            <ul class="bread-crumb clearfix">
                <li><a routerLink="/">{{ 'technology.Home' | translate }}</a></li>
                <li>{{ 'technology.Technology' | translate }}</li>
            </ul>
        </div>
    </div>
</section>

<section class="about-style-three" id="ssw">
    <div class="auto-container">
        <div class="row clearfix">
            <div class="col-xl-6 col-lg-12 col-sm-12">
                <figure><img src="{{techService.image}}" alt=""  class="brds"></figure>
            </div>
            <div class="col-xl-6 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="sec-title">
                            <span class="top-title">{{ 'technology.Nihon Edutech' | translate }}</span>
                            <h2>{{ 'technology.Tech Services' | translate }}</h2>
                        </div>
                        <div class="inner-box">
                            <p>{{techService.description1}}</p>
                            <p></p>
                            <div class="link"><a href="#" class="theme-btn-two">{{ 'technology.Request Service' | translate }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        <div class="row clearfix" style="margin-top:48px;">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                         <h3 class="h3_ttl" style="margin-bottom:30px;">{{ 'technology.Our technology focus areas are' | translate }}:</h3>
                        </div>
                    </div>
                    
                    <div class="tabs-content">
                <div class="tab">
                    <div class="row clearfix">
                        <div class="col-lg-2 col-md-6 col-sm-12 pricing-block">
                            <div class="pricing-block-one">
                                <div class="inner-box text_ctr">
                                        <i class="icon_tt fab fa-mixcloud"></i>
                                    <h3 class="s_sml">{{ 'technology.Cloud Support' | translate }}</h3>
                                    <ul class="list_4">
                                            <li>{{ 'technology.Cloud Implementation' | translate }}</li>
                                            <li>{{ 'technology.Cloud Support' | translate }}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-lg-2 col-md-6 col-sm-12 pricing-block">
                            <div class="pricing-block-one">
                                <div class="inner-box text_ctr">
                                    <i class="icon_tt fas fa-infinity"></i>
                                    <h3 class="s_sml">{{ 'technology.Devops' | translate }}</h3>
                                    <ul class="list_4">
                                            <li>{{ 'technology.Operational Management' | translate }}</li>
                                            <li>{{ 'technology.Enhanced Collaboration' | translate }}</li>
                                            <li>{{ 'technology.Continuous Deployment' | translate }}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 pricing-block">
                            <div class="pricing-block-one">
                                <div class="inner-box text_ctr">
                                    <i class="icon_tt fab fa-react"></i>
                                    <h3 class="s_sml">{{ 'technology.Digital Technology' | translate }}</h3>
                                        <ul class="list_4">
                                            <li>{{ 'technology.Full stack development (React.js, Node.js etc.)' | translate }}</li>
                                            <li>{{ 'technology.Progressive web development using ionic framework' | translate }}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 pricing-block">
                            <div class="pricing-block-one">
                                <div class="inner-box text_ctr">
                                    <i class="icon_tt fas fa-user-tie"></i>
                                    <h3 class="s_sml">{{ 'technology.Salesforce' | translate }}</h3>
                                        <ul class="list_4">
                                            <li>{{ 'technology.Functional Consulting' | translate }}</li>
                                            <li>{{ 'technology.Implementation' | translate }}</li>
                                            <li>{{ 'technology.Migration' | translate }}</li>
                                            <li>{{ 'technology.AMS' | translate }}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12 pricing-block">
                            <div class="pricing-block-one">
                                <div class="inner-box text_ctr">
                                    <i class="icon_tt fas fa-shield-alt"></i>
                                    <h3 class="s_sml">{{ 'technology.Cybersecurity/ Infosecurity' | translate }}</h3>
                                    <ul class="list_4">
                                            <li>{{ 'technology.Information Security Assessments' | translate }}</li>
                                            <li>{{ 'technology.Data Governance' | translate }}</li>
                                            <li>{{ 'technology.Cyber Risk Protection Platform' | translate }}</li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            
            
    </div>
</section>

<section class="growth-style-two bg-color-2" id="japanese_training">
    <div class="image-column" style="background-image: url(assets/images/background/growth-bg.jpg);"></div>
    <div class="auto-container">
        <div class="row align-items-center clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="sec-title">
                            <span class="top-title">{{ 'technology.Nihon Edutech' | translate }}</span>
                            <h2>{{ 'technology.Background Screening' | translate }}</h2>
                        </div>
                        <div class="inner-box">
                            <div class="text">
                                <p>{{techService.description2}}</p>
                                <p>{{techService.description3}}</p>
                            </div>
                           <div class="link"><a href="#" class="theme-btn-two">{{ 'technology.View More' | translate }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                <div class="text">
                    
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
</div>