import { Component, OnInit, AfterViewInit, AfterContentInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
declare let owlCarousel: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-japanese-training',
  templateUrl: './japanese-training.component.html',
  styleUrls: ['./japanese-training.component.css']
})
export class JapaneseTrainingComponent implements OnInit {
  title = 'NihonCareers | Japanese Training';
  bannerDetails:any={
    title:"Japanese Training",
    description:"The goal of education is understanding the goalof training is performance.",
    image:"assets/images/banner/banner-11.jpg",
  }
  japaneseTrain:any={
	description: "Ready-For-Japan program teaches Japanese language, culture, etiquettes and work models/practices to students and professionals in India so that they become proficient in Japanese language to live and work in Japan. Conducted through native-Japanese trainers, Japanese language, Japan-specific manners and business processes are taught with an impetus on spoken language. Students can choose from among the different training programs based on their existing proficiency level and to meet the required competency for the job/career they want to pursue in Japan.",
	image: "assets/images/project/course_japan_lang_b.jpg",
	video_link: "https://www.youtube.com/watch?v=v1hU6V2z9VQ"
  }
  videos:any;
  images:any;
  paragraphs:any;
  studentTestimonial:any;
  customOptionsVideos: OwlOptions = {
    loop:true,
	margin:30,
	nav:false,
	dots: false,
	smartSpeed: 1000,
	navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
	responsive:{
		0:{
			items:1
		},
		480:{
			items:2
		},
		600:{
			items:3
		},
		800:{
			items:4
		},			
		1200:{
			items:5
		}
	}
  }
  customOptionsStudent: OwlOptions = {
    loop:true,
	margin:30,
	nav:true,
	smartSpeed: 3000,
	dots: false,
	navText: [ '<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>' ],
	responsive:{
		0:{
			items:1
		},
		480:{
			items:1
		},
		600:{
			items:1
		},
		800:{
			items:2
		},
		1024:{
			items:2
		}
	}
  }
  constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    	translate.setDefaultLang(lng);
    	this.requestService.getJapaneseTrainingSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}
    updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getJapaneseTrainingBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          			this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
	);
	this.requestService.getJapaneseTraining()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          			this.japaneseTrain = data.data;
				}else{
				
				}
			},
			error => {
			}
	);
	this.requestService.getGallery(1,2)
		.pipe(first())
		.subscribe(
			data => {
				console.log('data videos',data);
				if(data.status == 1){
					this.videos = data.data;
					
				}else{
					this.videos =[
						{"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
						{"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getGallery(0,2)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.images = data.data;
				}else{
					this.images =[
						{"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
						{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getJapaneseTrainingParagraph()
		.pipe(first())
		.subscribe(
			data => {
				console.log("data paragraph",data)
				if(data.status == 1){
					this.paragraphs = data.data;
					setTimeout(()=>{
						$("#pera_expand0").click();
				   }, 2000);
				}else{
					
				}
			},
			error => {
			}
		);
		this.requestService.getStudentTestimonial(2)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.studentTestimonial = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
  }
}
