import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  title = 'NihonCareers | Sign Up';
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    if ($('.banner-carousel').length) {
      $('.banner-carousel').owlCarousel({
          loop:true,
          margin:0,
          nav:true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          active: true,
          smartSpeed: 1000,
          autoplay: 6000,
          navText: [ '<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>' ],
          responsive:{
              0:{
                  items:1
              },
              600:{
                  items:1
              },
              800:{
                  items:1
              },
              1024:{
                  items:1
              }
          }
      });
    }
  }

}
