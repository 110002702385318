import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
declare let $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { RequestService } from '../../../services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  loading = false;
  submitted = false;
  enqForm:any;
  selectedtype:any;
  errorMsg:any = "";
  successMsg:any = "";
  constructor(
    private formBuilder: FormBuilder,
    private requestService: RequestService,
  ) { 
    // this.enqForm = FormGroup;
  }

  public loadScript() {  
    var dynamicScripts = [
      ""+environment.base_url_assets+"/assets/js/script.js"
    ];      
    for (let i = 0; i < dynamicScripts.length; i++) {
      let node = document.createElement('script');
      node.src = dynamicScripts[i];
      document.getElementsByTagName('footer')[0].appendChild(node);
    }
  }

  changeSelectedType(type:any){
    this.selectedtype = type;
  }

  ngOnInit(): void {
    this.loadScript();
    if($('.scroll-to-target').length){
      $(".scroll-to-target").on('click', function() {
        var target = $(".scroll-to-target").attr('data-target');
         // animate
         $('html, body').animate({
           scrollTop: $(target).offset().top
         }, 1000);
      });
    }
    // if($('.preloader-close').length)$('.preloader-close').click();
    

    this.enqForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      type: ['']
    });
  }

  get f() { return this.enqForm.controls; }

  onSubmit(){
    this.enqForm.value.type = this.selectedtype;
    this.submitted = true;
    if (this.enqForm.invalid) {
        return;
    }
    this.loading = true;
    this.requestService.footerForm(this.enqForm.value)
    .pipe(first())
    .subscribe(
      data => {
        console.log('data',data);
        if(data.status == 1){
          this.successMsg = data.message;
          setTimeout(() => {
            this.successMsg = '';
          }, 1500);
        }else{
          this.errorMsg = data.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 1500);
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

}
