import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	title = 'Nihon Edutech Private Limite | Home';
	meta_desc = 'Nihon';
	meta_keyword = 'Nihon';
	slider1_title = "Nihon Edutech";
	slider1_desc1 = "is a company with singular focus on workforce creation for Japan Market";
	slider1_desc2 = "We serve customers across the industry verticals of Agriculture, Automobile, Construction, Financial Services, Healthcare and Marketing.";
	slider1_img   = "assets/images/banner/banner-1.jpg";
	slider1_btn_link = "#";
	slider2_title = "Get your dream job in Japan";
	slider2_desc1 = "By learng language and Technical skills";
	slider2_desc2 = "with Nihon Edutech";
	slider2_img   = "assets/images/banner/banner-2.jpg";
	slider2_btn_link = "#";
	slider3_title = "Recruit Trained staff for Japan";
	slider3_desc1 = "with language and technical skills";
	slider3_desc2 = "Trained by our experts at Nihon Edutech";
	slider3_img   = "assets/images/banner/banner-3.jpg";
	slider3_btn_link = "#";
	slider4_title = "Nihon Edutech offers solutions in niche technologies that can automate";
	slider4_desc1 = "Business processes and provide a superior ROI to customer’s technology investments.";
	slider4_desc2 = "The solutions offered include Staff Augmentation, Team Augmentation and Project services using onsite, offshore and nearshore delivery models.";
	slider4_img   = "assets/images/banner/banner-3a.jpg";
	slider4_btn_link = "#";

	teamData:any=[];
	studentTestimonial:any=[];
	
	whatWeDo:any = {
		description:"Nihon Edutech is a company with singular focus on workforce creation for Japan Market: from technical interns (TITP) to specific skilled workers (SSW) and highly skilled IT/Engineering professionals. Our unique business model, powered by our nihoncareers.com platform, connects Learners, Trainers, Recruiters and Corporates to upskill people (in Japanese & technical/technology skills) and create Japan-ready bilingual talent to match the job demands from Japan. We serve customers across the industry verticals of Agriculture, Automobile, Construction, Financial Service, Healthcare and Markerng.",
		image:"assets/images/home_img1.jpg",
		images:"assets/images/home_img2.jpg",
		video_image:"assets/images/gallery/video_img0.jpg",
		video_link:"https://www.youtube.com/embed/HjJAPlsS5z4"
	};
	address:any = {
		country1:"India",
		address1:"#4, 7th Street, Tansi Nagar, Taramani Link Road, Velachery, Chennai - 600 042",
		email1:"info@nihonedutech.com",
		phone1:"(+91) 8939581615",
		country2:"Japan",
		address2:"3-20-23, Edakita, Aoba-Ku, Yokohama-City, Kanagawa, Japan - 2250015",
		phone2:"info@nihonedutech.com",
		email2:"(+81) 45-913-8982"
	}
	videos:any;
  	images:any;
	partner:any = [];
	client:any = [];
	blogNews:any;
	technical_train:any= {
		title:"Technical Training Program",
		subtitle:"Long established fact that a reader will be distracted by the readable content of a page.",
		description1:"",
		description2:"",
		image:"assets/images/resource/welcome-1.png",
	}
	about_sec:any= {
		title:"Skills in Demand in Japan",
		subtitle:"Long established fact that a reader will be distracted by the readable content of a page.",
		description1:"",
		description2:"",
		image:"assets/images/resource/about-1.jpg",
	}
	recruitment_sec:any= {
		title:"Looking for",
		subtitle:"IT professional/skilled/ Semi skilled worker ??",
		description1:"",
		description2:"",
		image:"assets/images/resource/recruitment-1.png",
	}
	customOptionsVideos: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		dots: false,
		smartSpeed: 1000,
		autoplay: true,
		navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2
			},
			600:{
				items:3
			},
			800:{
				items:4
			},			
			1200:{
				items:5
			}
		}
	}
	customOptionsClient: OwlOptions = {
		loop:true,
		margin:0,
		nav:false,
		smartSpeed: 3000,
		autoplay: true,
		navText: [ '<span class="flaticon-arrowhead-thin-outline-to-the-left"></span>', '<span class="flaticon-right-arrow-2"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2
			},
			600:{
				items:3
			},
			800:{
				items:4
			},			
			1200:{
				items:6
			}

		}
	}
	customOptionsBlogNews: OwlOptions = {
		loop:true,
			margin:30,
			nav:true,
			smartSpeed: 1000,
			autoplay: true,
			navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
			responsive:{
				0:{
					items:1
				},
				480:{
					items:1
				},
				600:{
					items:2
				},
				800:{
					items:2
				},
				1024:{
					items:3
				}
			}
	}
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
		this.requestService.getHomeSlider()
			.pipe(first())
			.subscribe(
      		data => {
			if(data.status == 1){
				data.data.forEach((item:any)=>{
					if(item.slug == 1){
						this.slider1_title = item.title;
						this.slider1_desc1 = item.description1;
						this.slider1_desc2 = item.description2;
						this.slider1_img =	item.image;
						this.slider1_btn_link =	item.link_button;
					}
					if(item.slug == 2){
						this.slider2_title = item.title;
						this.slider2_desc1 = item.description1;
						this.slider2_desc2 = item.description2;
						this.slider2_img =	item.image;
						this.slider2_btn_link =	item.link_button;
					}
					if(item.slug == 3){
						this.slider3_title = item.title;
						this.slider3_desc1 = item.description1;
						this.slider3_desc2 = item.description2;
						this.slider3_img =	item.image;
						this.slider3_btn_link =	item.link_button;
					}
					if(item.slug == 4){
						this.slider4_title = item.title;
						this.slider4_desc1 = item.description1;
						this.slider4_desc2 = item.description2;
						this.slider4_img =	item.image;
						this.slider4_btn_link =	item.link_button;
					}
				})
			}else{
			
			}
			},
			error => {
			}
		);
		this.requestService.getDataBySlug(6)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.technical_train = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getDataBySlug(7)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.about_sec = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getDataBySlug(8)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.recruitment_sec = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getAllTeam()
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.teamData = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getStudentTestimonial(1)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.studentTestimonial = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getCompanyData(0)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.partner = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getCompanyData(1)
			.pipe(first())
			.subscribe(
			data => {
				console.log("client",data)
				if(data.status == 1){
					this.client = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getContactUsAddress()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.address = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getHomeSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getHomeWhatWeDo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.whatWeDo = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
		this.requestService.getGallery(1,1)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.videos = data.data;
				}else{
					this.videos =[
						{"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
						{"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
					]
				}
			},
			error => {
			}
		);
		this.requestService.getGallery(0,1)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.images = data.data;
				}else{
					this.images =[
						{"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
						{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
					]
				}
			},
			error => {
			}
		);
		this.requestService.getBlogsNewsData()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.blogNews = data.data;
				}else{
					// this.blogNews =[
					// 	{"title":"3 Surprising Culture Shocks for Indians in Japan","image":"assets/images/gallery/new_gal_img2.jpg"},
					// 	{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
					// ]
				}
			},
			error => {
			}
		);
	}
	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.metaService.addTags([
		{name: 'description', content: this.meta_desc},
		{name: 'keywords', content: this.meta_keyword},
		]);
		// $(".preloader-close").click();
  	}

  
}
