<div class="boxed_wrapper">
    <!-- <div class="loader-wrap">
    <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
    <div class="layer layer-one"><span class="overlay"></span></div>
    <div class="layer layer-two"><span class="overlay"></span></div>        
    <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->
<app-header></app-header>
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    <nav class="menu-box">
        <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
        <div class="menu-outer"></div>
    </nav>
</div>

<section class="banner-section style-three">
    <div class="banner-carousel owl-theme owl-carousel owl-dots-none">
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider1_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider1_title}}</h1>
                            <p>{{slider1_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider1_btn_link}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider2_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider2_title}}</h1>
                            <p>{{slider2_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider2_btn_link}}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
        <div class="slide-item">
            <div class="image-layer" style="background-image:url({{slider3_img}})"></div>
            <div class="auto-container">
                <div class="row clearfix">
                    <div class="col-lg-10 col-md-12 col-sm-12 offset-lg-2 content-column">
                        <div class="content-box">
                            <h1>{{slider3_title}}</h1>
                            <p>{{slider3_desc1}}</p>
                            <div class="btn-box">
                                <a href="{{slider3_btn_link}}" class="btn-one">{{ 'tech_services.Read Now' | translate }}</a>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section>

<div *ngFor="let item of partner; let i=index;">
    <section *ngIf="i == 0" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 1" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 2" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 3" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 4" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 5" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 6" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 7" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 8" class="about-style-three">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>Cloud Transformation &amp; Security Services</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                        </div>
                    </div>
            </div>
        </div>
    </section>
    <section *ngIf="i == 9" class="about-style-three bg-color-2a">
        <div class="auto-container">
            <div class="row clearfix">	
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <div class="sec-title">
                            <span class="top-title">{{item.title}}</span>
                        </div>
                    </div>
                <div class="col-xl-7 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box" [innerHTML]="item.description1">
                                <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                                <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                                <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                                <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                                <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                            </div>
                        </div>
                    </div>
                </div>	
                <div class="col-xl-5 col-lg-12 col-sm-12">
                    <div id="content_block_1">
                        <div class="content-box">
                            <div class="inner-box">
                                <img src="{{item.image}}" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;" [innerHTML]="item.description2">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- <section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Cloud - Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/cloud-services1.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Cloud Transformation &amp; Security Services</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three bg-color-2a">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">PLM - Partner</span>
                    </div>
                </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>PRODUCT LIFECYCLE MANAGEMENT (PLM) - Partner</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img2.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Avvanz Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img3.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Avvanz</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>

<section class="about-style-three bg-color-2a">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Web development Partner</span>
                    </div>
                </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Web development</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                      </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img4.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section>	

<section class="about-style-three">
    <div class="auto-container">
        <div class="row clearfix">	
                <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="sec-title">
                        <span class="top-title">Salesforce Partner</span>
                    </div>
                </div>
            <div class="col-xl-5 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <img src="assets/images/partners_img5.jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="inner-box">
                            <h2>Salesforce</h2>
                            <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                            <p>They have deep expertise in AWS, Azure and GCP cloud, enterprise security products, and compliances such as GxP, HIPAA, HiTrust, PCI etc.</p>
                            <p>Please refer to our partner <a href="pdf/semi_skilled.pdf" target="_blank">brochure</a>.</p>
                            <p>Please <a href="learnchain_rquest_form.html">click here</a> to contact our sales team.</p>
                        </div>
                    </div>
                </div>
            </div>	
            <div class="col-xl-12 col-lg-12 col-sm-12">
                    <div class="inner-box" style="margin-top:24px;">
                        <p>Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ. Nihon Edutech, in partnership with SecureKloud, brings in a host of cloud service offerings to the Japan market.  With core focus into security and compliance, especially for Life sciences and finance industry, SecureKloud brings hybrid-cloud migration capabilities and products such as CloudEZ and DataEZ.</p>
                    </div>
                </div>
        </div>
    </div>
</section> -->

<app-footer></app-footer>
</div>