import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-it-engineers',
  templateUrl: './it-engineers.component.html',
  styleUrls: ['./it-engineers.component.css']
})
export class ItEngineersComponent implements OnInit {
	title = 'Nihon Edutech Private Limite | IT/Engineering';
	bannerDetails:any={
		title:"IT/Engineering",
		description:"Bridging for Career in Japan",
		image:"assets/images/background/page-title.jpg",
	}
	engineering:any={
		title:"IT/Engineering",
		subtitle:"Bridging for Career in Japan",
		description1: "Ready-For-Japan teaches Japanese language, culture, etiquettes and work models/practices to students and professionals in India and find placement for them in Japanese organizations once they successfully complete the training.",
		description2: "Japan has increasing demand for software engineers with at least 2,00,000 information technology (IT) professionals, and the demand is likely to grow to 8,00,000 by 2030. Being at different economic stages, India and Japan can complement each other in many ways.",
		description3: "In order to take advantage of this economic scenario and provide Indian Software engineers an opportunity to work in Japan, Nihon Edutech conducts a convenient Ready-For-Japan program for IT / Engineering professionals whereby the candidates continue to work in projects while learning the Japanese language over a period of time and prepare them to professionally to work in Japan. IT / Engineering organizations, wishing to have presence in Japanese market can partner with Nihon Edutech through this program which will upgrade their employee’s skills and deploy their employees in Japanese organizations through Nihon Edutech partner agency in Japan.",
		image: "assets/images/tech_img1.jpg"
	}
	videos:any;
	images:any;
	paragraphs:any;
	studentTestimonial:any;
	customOptionsVideos: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		dots: false,
		smartSpeed: 1000,
		navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2
			},
			600:{
				items:3
			},
			800:{
				items:4
			},			
			1200:{
				items:5
			}
		}
	}
	customOptionsStudent: OwlOptions = {
		loop:true,
		margin:30,
		nav:true,
		smartSpeed: 3000,
		dots: false,
		navText: [ '<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:1
			},
			600:{
				items:1
			},
			800:{
				items:2
			},
			1024:{
				items:2
			}
		}
	}
	constructor(
		private titleService: Title,
		private metaService: Meta,
		private translate: TranslateService,
		private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
    	translate.setDefaultLang(lng);
		this.requestService.getItEngineeringSeo()
			.pipe(first())
			.subscribe(
				data => {
					if(data.status == 1){
						this.updateTitle(data.data.title);
						this.updateMetaDesc(data.data.description);
						this.updateMetaKey(data.data.keywords);
					}else{
					
					}
				},
				error => {
				}
		);
	}
  	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  	ngOnInit(): void {
		this.titleService.setTitle(this.title);
		this.metaService.addTags([
		{name: 'description', content: 'Nihon'},
		]);
		this.requestService.getItEngineeringBanner()
			.pipe(first())
			.subscribe(
				data => {
					if(data.status == 1){
						this.bannerDetails = data.data;
					}else{
					
					}
				},
				error => {
				}
		);
		this.requestService.getItEngineeringGetEng()
			.pipe(first())
			.subscribe(
				data => {
					console.log("engneering",data);
					if(data.status == 1){
						this.engineering = data.data;
					}else{
					
					}
				},
				error => {
				}
		);
		this.requestService.getGallery(1,5)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.videos = data.data;
					
				}else{
					this.videos =[
						{"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
						{"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getGallery(0,5)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.images = data.data;
				}else{
					this.images =[
						{"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
						{"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
					]
				}
			},
			error => {
			}
		);
	this.requestService.getParagraph(2)
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.paragraphs = data.data;
					setTimeout(()=>{
						$("#pera_expand0").click();
				   }, 2000);
				}else{
					
				}
			},
			error => {
			}
		);
		this.requestService.getStudentTestimonial(5)
			.pipe(first())
			.subscribe(
			data => {
				if(data.status == 1){
					this.studentTestimonial = data.data;
				}else{
				
				}
			},
			error => {
			}
		);
	}

}
