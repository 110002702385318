<div class="boxed_wrapper">
    <div class="loader-wrap">
    <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
    <div class="layer layer-one"><span class="overlay"></span></div>
    <div class="layer layer-two"><span class="overlay"></span></div>        
    <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header></app-header>
<!-- <header class="main-header">
    <div class="header-lower">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
                <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                <div class="mobile-nav-toggler">
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light">
                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul class="navigation clearfix">
                            <li class="dropdown"><a href="learning.html">Learning</a>
                                <ul>
                                    <li><a href="learning.html#japanese_training">Japanese Training</a></li>
                                    <li><a href="learning.html#technical_training">Technical Training</a></li>
                                    <li><a href="register.html">nihoncareers.com</a></li>
                                </ul>
                            </li>    
                            <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                <ul>
                                    <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                    <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                </ul>
                            </li> 
                            <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                <ul>
                                    <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                    <li><a href="resourcing_professionals.html">Professional</a></li>
                                </ul>
                            </li> 
                            <li class="dropdown"><a href="technology.html">Technology</a>
                                <ul>
                                    <li><a href="technology.html#tech_services">Tech Services</a></li>
                                    <li><a href="technology.html#background_screening">Background Screening</a></li>
                                    <li><a href="learning.html#learnchain">Learnchain</a></li>
                                </ul>
                            </li> 
                            <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                              <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a href="register.html" class="theme-btn-one">Register</a>
                </li>
                <li>
                    <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                </li>
            </ul>
        </div>
    </div>
    
    <div class="sticky-header">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left">
                <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                <nav class="main-menu clearfix">
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="search-btn">
                        <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                    </div>
                </li>
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                            <ul>
                              <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a href="register.html" class="theme-btn-one">Register</a>
                </li>
                <li>
                    <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                </li>
            </ul>
        </div>
    </div>
</header>
    
    <div class="sticky-header">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left">
                <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                <nav class="main-menu clearfix">
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="search-btn">
                        <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                    </div>
                </li>
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                            <ul>
                              <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a href="index.html" class="theme-btn-one">Appointment</a>
                </li>
            </ul>
        </div>
    </div>
</header> -->
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    <nav class="menu-box">
        <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
        <div class="menu-outer"></div>
    </nav>
</div>
<section class="page-title" style="background-image: url({{bannerDetails.image}});">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
    <div class="auto-container">
        <div class="content-box">
            <div class="title-box centred">
                <h1>{{bannerDetails.title}}</h1>
                <p>{{bannerDetails.description}}</p>
            </div>
            <ul class="bread-crumb clearfix">
                <li><a routerLink="/">{{ 'job_in_japan_prof.Home' | translate }}</a></li>
                <li>{{ 'job_in_japan_prof.IT/Engineering' | translate }}</li>
            </ul>
        </div>
    </div>
</section>

<section class="about-style-three" id="learnchain">
    <div class="auto-container">
        <div class="row clearfix">
            <div class="col-xl-6 col-lg-12 col-sm-12">
                <figure><img src="{{engDetails.image}}" alt="" class="brds"></figure>
            </div>
            <div class="col-xl-6 col-lg-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="sec-title">
                            <span class="top-title">{{ 'job_in_japan_prof.Overview' | translate }}</span>
                            <h2>{{ 'job_in_japan_prof.Engineering' | translate }}</h2>
                        </div>
                        <div class="inner-box">
                            <p>{{engDetails.description1}}</p>
                            <p>{{engDetails.description2}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="job-details-content">
                <div class="text">        
                        <p>{{engDetails.description3}}</p>
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="tabs-box">
                            <div class="tab-btn-box">
                                <ul class="tab-btns tab-buttons clearfix">
                                    <li class="tab-btn active-btn" data-tab="#tab-1">
                                        <i class="employ-icon flaticon-businessman"></i>
                                        <h5>Overview</h5>
                                        <i class="arrow-icon flaticon-up-arrow-2"></i>
                                    </li>
                                    <li class="tab-btn" data-tab="#tab-2">
                                        <i class="employ-icon far fa-image"></i>
                                        <h5>Images</h5>
                                        <i class="arrow-icon flaticon-up-arrow-2"></i>
                                    </li>
                                    <li class="tab-btn" data-tab="#tab-3">
                                        <i class="employ-icon fas fa-book-reader"></i>
                                        <h5>Brochures</h5>
                                        <i class="arrow-icon flaticon-up-arrow-2"></i>
                                    </li>
                                    <li class="tab-btn" data-tab="#tab-4">
                                        <i class="employ-icon fas fa-video"></i>
                                        <h5>Videos</h5>
                                        <i class="arrow-icon flaticon-up-arrow-2"></i>
                                    </li>
                                    <li class="tab-btn" data-tab="#tab-5">
                                        <i class="employ-icon far fa-comment-dots"></i>
                                        <h5>FAQ</h5>
                                        <i class="arrow-icon flaticon-up-arrow-2"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="tabs-content">
                                <div class="tab active-tab" id="tab-1">
                                    <div class="inner-box">
                                        <h2 class="h2_tt">Key Parties</h2>
                                        <div class="col-xl-6 col-lg-12 col-sm-12">
                                            <h3 class="h3_tt">IT / Engineering Firms</h3>
                                            <p>Indian Companies who look forward to enter Japan market with their employees and offerings and expand their footprint to global locations.</p>
                                            <h3 class="h3_tt">Nihon Edutech</h3>
                                            <p>Nihon Edutech will conduct the extensive Japanese language / culture / etiquettes training program in India using native Japanese teachers and using a proven curriculum.</p>
                                            <h3 class="h3_tt">Japan Employer</h3>
                                            <p>Japanese company in which the candidates will be deployed for work through a contract with Nihon Edutech Japan Agency, which will in turn have a back-to-back arrangement to contract IT / Engineering employees who have successfully completed the training.</p>
                                        
                                        </div>
                                        <div class="col-xl-6 col-lg-12 col-sm-12">
                                            <h3 class="h3_tt">Candidates</h3>
                                            <p>People who are either employees of IT / Engineering or who wish to build a career in Japan on their own can enroll to this program.</p>
                                            <h3 class="h3_tt">Nihon Edutech Japan Agency</h3>
                                            <p>Entity established in Japan that helps in placing the successful candidates in Japan employer organizations, in collaboration with Nihon Edutech.</p>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="col-xl-6 col-lg-12 col-sm-12">
                                        <div class="job-details-content">
                                            <div class="text">
                                                <h3>Key Features :</h3>
                                                <ul class="list clearfix">
                                                    <li>The program is designed to teach Japanese language from zero level to JLPT N3 level equivalent over a period of 8 months</li>
                                                    <li>The classes will be conducted for a maximum of 4 hours per day and rest of the day can be used for working in projects or honing the technical skills</li>
                                                    <li>The mode of teaching will be a combination of class room sessions, self-study and practice sessions, role plays and assessments at various stages of learning</li>
                                                    <li>For successful candidates Nihon Edutech will assist in finding placement in Japanese organizations through its Japan based partner agency</li>
                                                    <li>The tuition fees will be collected on installment basis to ease the cash flow pressure on students and Companies</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-sm-12">
                                        <div class="job-details-content">
                                            <div class="text">
                                                <h3>Benefits :</h3>
                                                <ul class="list clearfix">
                                                    <li>Higher financial benefits and better savings for candidates/employees</li>
                                                    <li>Additional and elevated revenue streams for compannies and improvement in brand</li>
                                                    <li>Experience the working conditions in Japan</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="growth-style-two bg-color-2">
    <div class="auto-container">
        <div class="row align-items-center clearfix">
            <div class="col-xl-6 col-lg-12 col-sm-12">
                 <div id="content_block_1">
                    <div class="content-box">
                        <div class="sec-title">
                            <span class="top-title">Nihon</span>
                            <h2>IT Professionals</h2>
                        </div>
                        <div class="inner-box">
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
                </div>
            <div class="col-xl-6 col-lg-12 col-sm-12">
                <p>&nbsp;</p>
            </div>
        
        </div>
    </div>
</section>
<app-footer></app-footer>
<!-- <footer class="main-footer style-two bg-color-1">
    <div class="auto-container">
        <div class="footer-top">
            <div class="widget-section">
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget about-widget">
                            <div class="widget-title">
                                <h3>About Company</h3>
                            </div>
                            <div class="text">
                                <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="links-list clearfix">
                                <li><a href="aboutus.html">About Us</a></li>
                                <li><a href="learning.html">Learning</a></li>
                                <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>	
                                <li><a href="resourcing.html">Resourcing</a></li>
                                <li><a href="technology.html">Technology</a></li>
                                <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                <li><a href="contactus.html">Contact Us</a></li>
                                <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                <li><a href="schedule_session.html">Schedule a Session</a></li>
                            </ul>
                            <div class="about-widget">
                            <ul class="social-links clearfix">
                                <li><h5>Connected:</h5></li>
                                <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div></div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                        <div class="footer-widget callback-widget">
                            <div class="pattern-layer">
                                <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                <div class="pattern-2"></div>
                                <div class="pattern-3"></div>
                                <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                            </div>
                            <div class="widget-content">
                                <form action="index-2.html" method="post" class="callback-form">
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="name" placeholder="Name*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="email" name="email" placeholder="Email*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="phone" placeholder="Phone*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="select-box">
                                    <select class="wide" style="display: none;">
                                       <option data-display="Please Select">Please Select</option>
                                       <option value="1">TITP</option>
                                       <option value="2">SSW/SSP</option>
                                       <option value="3">IT/Engineering</option>
                                    </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button type="submit" class="theme-btn-one">Get Call Back</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom clearfix">
            <div class="copyright pull-left">
                <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
            </div>
            <ul class="footer-nav pull-right clearfix">
                <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
            </ul>
        </div>
    </div>
</footer>
<button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>