<div class="boxed_wrapper">
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div>
    <app-header></app-header>
     <!-- <header class="main-header">
        <div class="header-lower">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left clearfix">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/logo.png" alt=""></a></figure>
                    <div class="mobile-nav-toggler">
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                        <i class="icon-bar"></i>
                    </div>
                    <nav class="main-menu navbar-expand-md navbar-light">
                        <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                            <ul class="navigation clearfix">
                                <li class="dropdown"><a href="learning.html">Learning</a>
                                    <ul>
                                        <li><a href="learning.html#japanese_training">Japanese Training</a></li>
                                        <li><a href="learning.html#technical_training">Technical Training</a></li>
                                        <li><a href="register.html">nihoncareers.com</a></li>
                                    </ul>
                                </li>    
                                <li class="dropdown"><a href="jobs_in_japan.html">Jobs in Japan</a>
                                    <ul>
                                        <li><a href="jobs_in_japan_semi_skilled.html">Semi Skilled</a></li>
                                        <li><a href="jobs_in_japan_professional.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="resourcing.html">Resourcing</a>
                                    <ul>
                                        <li><a href="resourcing_semi_skilled.html">Semi Skilled</a></li>	
                                        <li><a href="resourcing_professionals.html">Professional</a></li>
                                    </ul>
                                </li> 
                                <li class="dropdown"><a href="technology.html">Technology</a>
                                    <ul>
                                        <li><a href="technology.html#tech_services">Tech Services</a></li>
                                        <li><a href="technology.html#background_screening">Background Screening</a></li>
                                        <li><a href="learning.html#learnchain">Learnchain</a></li>
                                    </ul>
                                </li> 
                                <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                  <ul>
                                      <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="sticky-header">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                    <nav class="main-menu clearfix">
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="search-btn">
                            <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                        </div>
                    </li>
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="register.html" class="theme-btn-one">Register</a>
                    </li>
                    <li>
                        <a href="schedule_session.html" class="theme-btn-one">Join a Demo Class</a>
                    </li>
                </ul>
            </div>
        </div>
    </header>
        <div class="sticky-header">
            <div class="outer-box clearfix">
                <div class="menu-area pull-left">
                    <figure class="logo-box"><a href="index.html"><img src="assets/images/small-logo.png" alt=""></a></figure>
                    <nav class="main-menu clearfix">
                    </nav>
                </div>
                <ul class="menu-right-content pull-right clearfix">
                    <li>
                        <div class="search-btn">
                            <button type="button" class="search-toggler"><i class="flaticon-loupe-1"></i></button>
                        </div>
                    </li>
                    <li>
                        <div class="language">
                            <div class="lang-btn">
                                <i class="icon flaticon-planet-earth"></i>
                                <span class="txt">EN</span>
                                <span class="arrow fa fa-angle-down"></span>
                            </div>
                            <div class="lang-dropdown">
                                <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                      <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="index.html" class="theme-btn-one">Appointment</a>
                    </li>
                </ul>
            </div>
        </div>
    </header> -->
    <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn"><i class="fas fa-times"></i></div>
        <nav class="menu-box">
            <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
            <div class="menu-outer"></div>
        </nav>
    </div>
    <section class="page-title" style="background-image: url({{bannerDetails.image}});">
        <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
        <div class="auto-container">
            <div class="content-box">
                <div class="title-box centred">
                    <h1>{{bannerDetails.title}}</h1>
                    <p>{{bannerDetails.description}}</p>
                </div>
                <ul class="bread-crumb clearfix">
                    <li><a routerLink="/">{{ 'schedule-session.Home' | translate }}</a></li>
                    <li>{{ 'schedule-session.Schedule a Session' | translate }}</li>
                </ul>
            </div>
        </div>
    </section>
  
    <section class="portfolio-wide-1 placejob-section">
        <div class="auto-container">
            <div class="sec-title centred">
                <span class="top-title">{{ 'schedule-session.Schedule a Session' | translate }}</span>
                <p>{{ 'schedule-session.Book a Counselling Session with our Overseas and Training Counsellor' | translate }}</p>
            </div>
        </div>
        <div class="items-container row clearfix">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <figure><img src="assets/images/schedule_left.jpg" alt="" class="brds"></figure>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 form-column">
                <div class="form-inner">
                    <b style="color: red;">{{ errorMsg }}</b>
                    <b style="color: green;">{{ successMsg }}</b>
                    <form [formGroup]="enqForm" (ngSubmit)="onSubmit()" method="post" class="job-form">
                        <div class="row clearfix">
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" placeholder="{{ 'schedule-session.First Name' | translate }} *"
                                formControlName="first_name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                                <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.first_name.errors.required">{{ 'schedule-session.First name is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" placeholder="{{ 'schedule-session.Last Name' | translate }} *" 
                                formControlName="last_name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.last_name.errors.required">{{ 'schedule-session.Last name is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" placeholder="{{ 'schedule-session.Email' | translate }} *"
                                formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">{{ 'schedule-session.Email is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" name="lname" placeholder="{{ 'schedule-session.Mobile' | translate }} *"
                                formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile.errors.required">{{ 'schedule-session.Mobile is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" placeholder="{{ 'schedule-session.Work Experience (Years)' | translate }} *"
                                formControlName="experience" [ngClass]="{ 'is-invalid': submitted && f.experience.errors }">
                                <div *ngIf="submitted && f.experience.errors" class="invalid-feedback">
                                    <div *ngIf="f.experience.errors.required">{{ 'schedule-session.Work Experience is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                <input type="text" placeholder="{{ 'schedule-session.Job role' | translate }} *"
                                formControlName="job_role" [ngClass]="{ 'is-invalid': submitted && f.job_role.errors }">
                                <div *ngIf="submitted && f.job_role.errors" class="invalid-feedback">
                                    <div *ngIf="f.job_role.errors.required">{{ 'schedule-session.Job role is required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <textarea name="message" placeholder="{{ 'schedule-session.Please provide a free slot when our counsellor can reach out to you:' | translate }}"
                                formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required">{{ 'schedule-session.required' | translate }}</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                <div class="form-group">
                                    <button type="submit" [disabled]="loading" class="theme-btn-one">{{ 'schedule-session.Submit' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <figure><img src="assets/images/login_right_jobs.jpg" alt="" class="brds"></figure>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>