<header class="main-header">
    <div class="header-lower">
        <div class="outer-box clearfix">
            <div class="menu-area pull-left clearfix">
                <figure class="logo-box"><a routerLink="/"><img src="assets/images/logo.png" alt=""></a></figure>
                <div class="mobile-nav-toggler">
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                    <i class="icon-bar"></i>
                </div>
                <nav class="main-menu navbar-expand-md navbar-light">
                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul class="navigation clearfix">
                            <li class="dropdown"><a routerLink="#">{{ 'header-one.learning' | translate }}</a><!--routerLink="/japanese-training"-->
                                <ul>
                                    <li><a routerLink="/japanese-training">{{ 'header-one.Japanese_Training' | translate }}</a></li>
                                    <li><a routerLink="/technical_training">{{ 'header-one.Technical Training' | translate }}</a></li>
                                </ul>
                            </li>    
                            <li class="dropdown"><a routerLink="#">{{ 'header-one.Jobs_in_Japan' | translate }}</a><!--routerLink="/jobs_in_japan"-->
                                <ul>
                                    <li><a routerLink="/jobs_in_japan_semi_skilled">{{ 'header-one.Semi-Skilled & Technical Interns' | translate }}</a></li>
                                    <li><a routerLink="/it_engineers">{{ 'header-one.IT Engineers' | translate }}</a></li>
                                </ul>
                            </li> 
                            <li class="dropdown"><a routerLink="#">{{ 'header-one.Resourcing' | translate }}</a><!-- routerLink="/resourcing" -->
                                <ul>
                                    <li><a routerLink="/resourcing_semi_skilled">{{ 'header-one.Semi-Skilled' | translate }}</a></li>
                                    <li><a routerLink="/resourcing_professional">{{ 'header-one.IT Professionals' | translate }}</a></li>	
                                </ul>
                            </li> 
                            <li class="dropdown"><a routerLink="#">{{ 'header-one.Technology' | translate }}</a><!--routerLink="/technology"-->
                                <ul>
                                    <li><a routerLink="/tech_services">{{ 'header-one.Tech_Services' | translate }}</a></li>
                                    <li><a routerLink="/learnchain">{{ 'header-one.Learnchain' | translate }}</a></li>
                                </ul>
                            </li> 
                            <li class="btn_top "><a routerLink="/schedule_session" class="theme-btn-one">Join a Demo Class</a></li>
                        </ul>
                    </div>
                </nav>
            </div>
            <ul class="menu-right-content pull-right clearfix">
                <li>
                    <div class="language">
                        <div class="lang-btn">
                            <i class="icon flaticon-planet-earth"></i>
                            <span class="txt">EN</span>
                            <span class="arrow fa fa-angle-down"></span>
                        </div>
                        <div class="lang-dropdown">
                            <ul>
                                <li (click)="lngChangeHandler('en')"><a class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png">ENGLISH</a></li>
                                <li (click)="lngChangeHandler('ja')"><a class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png">JAPANESE</a></li>
                            </ul>
                              <!-- <ul>
                                  <li><a href="#googtrans(en|en)" class="lang-en lang-select" data-lang="en"><img src="assets/images/flag_usa.png"> ENGLISH</a></li>
                                  <li><a href="#googtrans(en|ja)" class="lang-es lang-select" data-lang="ja"><img src="assets/images/flag_japan.png"> JAPANESE</a></li>
                            </ul> -->
                        </div>
                    </div>
                </li>
                <li>
                    <a href="https://nihoncareers.com/" target="_blank" class="theme-btn-one  btntop1">NihonCareers.com</a>
                </li>
            </ul>
        </div>
    </div>
</header>