<div class="boxed_wrapper">
    <div class="loader-wrap">
    <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
    <div class="layer layer-one"><span class="overlay"></span></div>
    <div class="layer layer-two"><span class="overlay"></span></div>        
    <div class="layer layer-three"><span class="overlay"></span></div>        
</div>
<app-header></app-header>
<div class="mobile-menu">
    <div class="menu-backdrop"></div>
    <div class="close-btn"><i class="fas fa-times"></i></div>
    <nav class="menu-box">
        <div class="nav-logo"><a routerLink="/"><img src="assets/images/small-logo.png" alt="" title=""></a></div>
        <div class="menu-outer"></div>
    </nav>
</div>
<section class="page-title" style="background-image: url({{bannerDetails.image}});">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-35.png);"></div>
    <div class="auto-container">
        <div class="content-box">
            <div class="title-box centred">
                <h1>{{bannerDetails.title}}</h1>
                <p>{{bannerDetails.description}}</p>
            </div>
            <ul class="bread-crumb clearfix">
                <li><a routerLink="/">{{ 'jobs_in_japan.Home' | translate }}</a></li>
                <li>{{ 'jobs_in_japan.TITP' | translate }}</li>
            </ul>
        </div>
    </div>
</section>

<section class="advantages-section">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-46.png);"></div>
    <div class="auto-container">
       <div class="sec-title centred">
            <span class="top-title">{{ 'jobs_in_japan.TITP (Technical Intern Training Program)' | translate }}</span>
            <h2>{{ 'jobs_in_japan.UNDER MOU BETWEEN GOVERNMENTS OF JAPAN & INDIA' | translate }}</h2>
           <p>{{ 'jobs_in_japan.Nihon Edutech in consortium with Confederation of Indian Industry (CII) is the Leading Sending Organization of India' | translate }}</p>
        </div>
     <div class="row clearfix">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="single-item">
                                <div class="inner-box">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-44.png" alt=""></figure>
                                    <h3>{{ 'jobs_in_japan.JAPAN PLACEMENTS' | translate }}</h3>
                                    <p>{{titpDetails.japan_placement}}</p>
                                    <!-- <p>2020 : 120+ Trainees<br>
                                    2021 : 150+ Trainees</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="single-item">
                                <div class="inner-box">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-45.png" alt=""></figure>
                                    <h3>{{ 'jobs_in_japan.SEGMENTS' | translate }}</h3>
                                    <!-- <p>Automobile, Manufacturing,<br>
                                        Agriculture, Construction,<br>
                                        Careworker
                                        </p> -->
                                        <p>{{titpDetails.segments}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                              <div class="single-item">
                                <div class="inner-box">
                                    <figure class="icon-box"><img src="assets/images/icons/icon-47.png" alt=""></figure>
                                    <h3>{{ 'jobs_in_japan.MONTHLY SALARY' | translate }}</h3>
                                    <!-- <p>Rs.1 Lakh to 1.5 Lakh</p> -->
                                    <p>{{titpDetails.monthly_salary}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        

<section class="growth-style-two bg-color-2">
    <div class="image-column" style="background-image: url(assets/images/background/growth-bg.jpg);"></div>
    <div class="auto-container">
        <div class="row align-items-center clearfix">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
                <div id="content_block_1">
                    <div class="content-box">
                        <div class="sec-title">
                            <span class="top-title">{{ 'jobs_in_japan.Nihon Edutech' | translate }}</span>
                            <h2>{{ 'jobs_in_japan.TITP (Technical Intern Training Program)' | translate }}</h2>
                        </div>
                        <div class="inner-box">
                            <div class="text">
                                <p>{{titpDetails.description}}</p>
                            </div>
                           
                            <div class="link"><a href="#"><i class="flaticon-right-arrow"></i>{{ 'jobs_in_japan.Job Opportunities' | translate }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 inner-column">
                <div class="text">
                    <h2>{{ 'jobs_in_japan.TITP/SSW' | translate }}</h2>
                    <p>{{ 'jobs_in_japan.Technical Interns and Specific Skilled Workers” (TITP/SSW) and IT / High Skill Professionals' | translate }}</p>
                    <a href="#.html" class="theme-btn-two">{{ 'jobs_in_japan.Apply Now' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="advantages-section">
    <div class="pattern-layer" style="background-image: url(assets/images/shape/pattern-46.png);"></div>
    <div class="auto-container centred">
       <div class="sec-title centred">
            <span class="top-title">{{ 'jobs_in_japan.TITP' | translate }}</span>
            <h2>{{ 'jobs_in_japan.Process' | translate }}</h2>
        </div>
        <figure class="image-box"><img src="assets/images/titp-process.jpg" alt=""></figure>
    </div>
</section>
<app-footer></app-footer>
<!-- <footer class="main-footer style-two bg-color-1">
    <div class="auto-container">
        <div class="footer-top">
            <div class="widget-section">
                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget about-widget">
                            <div class="widget-title">
                                <h3>About Company</h3>
                            </div>
                            <div class="text">
                                <p>NIHON EDUTECH has a singular focus on workforce creation for Japan Market. Our unique recruitment, training and placement strategy connects Learners, Trainers and Recruiters to build large Japan-ready talent pool through training and help match the job demands in Japan.<i class="fas fa-arrow-up"></i></p>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                        <div class="footer-widget links-widget">
                            <div class="widget-title">
                                <h3>Useful Links</h3>
                            </div>
                            <ul class="links-list clearfix">
                                <li><a href="aboutus.html">About Us</a></li>
                                <li><a href="learning.html">Learning</a></li>
                                <li><a href="jobs_in_japan.html">Jobs in Japan</a></li>	
                                <li><a href="resourcing.html">Resourcing</a></li>
                                <li><a href="technology.html">Technology</a></li>
                                <li><a href="blogs_news.html">Blogs &amp; News</a></li>
                                <li><a href="contactus.html">Contact Us</a></li>
                                <li><a href="privacy_policy.html">Privacy Policy</a></li>
                                <li><a href="schedule_session.html">Schedule a Session</a></li>
                            </ul>
                            <div class="about-widget">
                            <ul class="social-links clearfix">
                                <li><h5>Connected:</h5></li>
                                <li><a href="https://www.facebook.com/nihoncareers"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/nihoncareers1"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/nihonedutech"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div></div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 footer-column">
                        <div class="footer-widget callback-widget">
                            <div class="pattern-layer">
                                <div class="pattern-1" style="background-image: url(assets/images/shape/pattern-25.png);"></div>
                                <div class="pattern-2"></div>
                                <div class="pattern-3"></div>
                                <div class="pattern-4" style="background-image: url(assets/images/shape/pattern-23.png);"></div>
                            </div>
                            <div class="widget-content">
                                <form action="index-2.html" method="post" class="callback-form">
                                    <div class="row clearfix">
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="name" placeholder="Name*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="email" name="email" placeholder="Email*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <input type="text" name="phone" placeholder="Phone*" required="">
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <div class="select-box">
                                    <select class="wide" style="display: none;">
                                       <option data-display="Please Select">Please Select</option>
                                       <option value="1">TITP</option>
                                       <option value="2">SSW/SSP</option>
                                       <option value="3">IT/Engineering</option>
                                    </select><div class="nice-select wide" tabindex="0"><span class="current">Please Select</span><ul class="list"><li data-value="Please Select" data-display="Please Select" class="option selected focus">Please Select</li><li data-value="1" class="option">TITP</li><li data-value="2" class="option">SSW/SSP</li><li data-value="3" class="option">IT/Engineering</li></ul></div>
                                </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                            <button type="submit" class="theme-btn-one">Get Call Back</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom clearfix">
            <div class="copyright pull-left">
                <p>Copyrights &copy; 2021 Nihon Edutech Private Limited. All Rights Reserved.</p>
            </div>
            <ul class="footer-nav pull-right clearfix">
                <li>Designed &amp; Developed by <a href="http://digiteratech.com/" target="_blank"> Digitera Technologies</a></li>
            </ul>
        </div>
    </div>
</footer>
<button class="scroll-top scroll-to-target" data-target="html"><i class="flaticon-up-arrow-1"></i>Top</button> -->
</div>