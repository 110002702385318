import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
declare let $: any;
import { first } from 'rxjs/operators';
import { RequestService } from '../../services';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-learnchain',
  templateUrl: './learnchain.component.html',
  styleUrls: ['./learnchain.component.css']
})
export class LearnchainComponent implements OnInit {
  title = 'Nihon Edutech Private Limite | Learnchain';
  bannerDetails:any={
    title:"Learnchain",
    description:"Use existing training setup and revenue as base and leverage track record to acquire employers",
    image:"assets/images/background/page-title.jpg",
  }
  learnChainExp:any={
    title: "Learnchain",
    subtitle: "Provides learning personalization and customization capabilities",
	description1: "Learnchain is a robust Marketplace Platform for Trainers to launch and manage training programs, for Learners to access learning Anytime | Anywhere | Anydevice | Anysource, and for Recruiters to help post and search for best talent.",
	description2: "Learnchain is a robust Marketplace Platform for Trainers to launch and manage training programs, for Learners to access learning Anytime | Anywhere | Anydevice | Anysource, and for Recruiters to help post and search for best talent.",
	description3: "Learnchain is a robust Marketplace Platform for Trainers to launch and manage training programs, for Learners to access learning Anytime | Anywhere | Anydevice | Anysource, and for Recruiters to help post and search for best talent.",
    image: "assets/images/learnchain_img1.jpg",
  }
  videos:any;
	images:any;
	paragraphs:any;
	studentTestimonial:any;
	customOptionsVideos: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		dots: false,
		smartSpeed: 1000,
		navText: [ '<span class="flaticon-left-arrow-1"></span>', '<span class="flaticon-right-arrow"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:2
			},
			600:{
				items:3
			},
			800:{
				items:4
			},			
			1200:{
				items:5
			}
		}
	}
	customOptionsStudent: OwlOptions = {
		loop:true,
		margin:30,
		nav:true,
		smartSpeed: 3000,
		dots: false,
		navText: [ '<span class="flaticon-left-2"></span>', '<span class="flaticon-right-1"></span>' ],
		responsive:{
			0:{
				items:1
			},
			480:{
				items:1
			},
			600:{
				items:1
			},
			800:{
				items:2
			},
			1024:{
				items:2
			}
		}
	}
	constructor(
		private titleService: Title,
		private metaService: Meta,
    	private translate: TranslateService,
    	private requestService: RequestService
	) {
		let lng = localStorage.getItem('lng');
		if(lng == null || lng == '' || lng == undefined)lng = 'en';
		translate.setDefaultLang(lng);
		this.requestService.getLearnchainSeo()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
					this.updateTitle(data.data.title);
					this.updateMetaDesc(data.data.description);
					this.updateMetaKey(data.data.keywords);
				}else{
				
				}
			},
			error => {
			}
		);
	}
  	updateTitle(title: string) {
		this.titleService.setTitle(title);
	}
	updateMetaDesc(contant: string) {
		this.metaService.updateTag({name: 'description', content: contant})
	}
	updateMetaKey(contant: string) {
		this.metaService.updateTag({name: 'keywords', content: contant})
	}
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'Nihon'},
    ]);
    this.requestService.getLearnchainBanner()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          this.bannerDetails = data.data;
				}else{
				
				}
			},
			error => {
			}
    );
    this.requestService.getLearnchainLearnExp()
		.pipe(first())
		.subscribe(
			data => {
				if(data.status == 1){
          			this.learnChainExp = data.data;
				}else{
				
				}
			},
			error => {
			}
	  );
	  this.requestService.getGallery(1,8)
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.videos = data.data;
            
          }else{
            this.videos =[
              {"title":"Our Training Program","image":"assets/images/gallery/video_img1.jpg","video_link":"https://www.youtube.com/embed/NtjriBQMArQ"},
              {"title":"2021-Japan Job Opportunities","image":"assets/images/gallery/video_img2.jpg","video_link":"https://www.youtube.com/embed/XEC8BsuYALM"}
            ]
          }
        },
        error => {
        }
      );
    this.requestService.getGallery(0,8)
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.images = data.data;
          }else{
            this.images =[
              {"title":"CareWorker interns Training","image":"assets/images/gallery/new_gal_img2.jpg"},
              {"title":"Manufacturing intern trainging","image":"assets/images/gallery/new_gal_img1.jpg"}
            ]
          }
        },
        error => {
        }
      );
    this.requestService.getParagraph(5)
      .pipe(first())
      .subscribe(
        data => {
          if(data.status == 1){
            this.paragraphs = data.data;
            setTimeout(()=>{
              $("#pera_expand0").click();
            }, 2000);
          }else{
            
          }
        },
        error => {
        }
      );
      this.requestService.getStudentTestimonial(8)
        .pipe(first())
        .subscribe(
        data => {
          if(data.status == 1){
            this.studentTestimonial = data.data;
          }else{
          
          }
        },
        error => {
        }
      );
  }

}
